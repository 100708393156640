import React, {useState} from 'react';

import{
    TextField,
    Button,
    Grid,
    makeStyles,
    Typography,
    Switch,
    withStyles
} from '@material-ui/core';
import axios from 'axios';

const ADD_ADMIN_FROM_EMAIL_ENDPOINT = '/addAdminFromEmail';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "5%",
        marginTop: "3%",
        height: "80vh"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    sectionHeader: {
        fontWeight: "bold",
        fontSize: "1.5em"
    }
}));

const CustomSwitch = withStyles({
    switchBase: {
      color: 'red',
      '&$checked': {
        color: 'green',
      },
      '&$checked + $track': {
        backgroundColor: 'green',
      },
    },
    checked: {},
    track: {
      backgroundColor: 'red',
    },
  })(Switch);

export default function AddAdminFromEmail(){

    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [addAdmin, setAddAdmin] = useState(true);
    

    const handleEmailSubmit = () => {
        if(email === ""){
            alert("Email cannot be empty");
            return;
        }
        axios.post("/api" + ADD_ADMIN_FROM_EMAIL_ENDPOINT, {
            email: email,
            addAdmin: addAdmin
        })
        .then(res => {
            alert(res.data);
            setEmail("");
        })
        .catch(e => {
            console.log(e);
            alert(e.response.data);
        })
    }

    return(
        <div className={classes.root}>
        <Grid container spacing={7} direction='column'>
            <Grid container item>
                <Typography className={classes.pageHeader}>Add Admin by Email</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='center'>
                <Grid item><Typography>Delete Admin</Typography></Grid>
                <Grid item>
                    <CustomSwitch
                        checked={addAdmin}
                        onChange={(e) => setAddAdmin(e.target.checked)}
                        name="addAdmin"
                    />
                </Grid>
                <Grid item><Typography>Add Admin</Typography></Grid>
            </Grid>
            <Grid container item>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}><Typography className={classes.sectionHeader}>Add / Remove Admin</Typography></Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth variant="outlined" onChange={(e) => setEmail(e.target.value)} value={email} label={`Enter email to ${addAdmin ? 'add' : 'remove'} admin`}/>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' color='primary' onClick={handleEmailSubmit}>{addAdmin ? 'Add' : 'Remove'} Admin</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </div>
    );
}