/* QuoteView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import {Redirect, withRouter} from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Hidden,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useSnackbar } from "notistack";
import axios from "axios";

const GET_CUST_ID = '/getArkeoCompanyId/';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 100,
  },
  sectionHeader: {
    fontSize: 28,
    fontWeight: "bold",
  },
  appBar: {
    width: "100vw",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: 85,
  },
  leftSide: {
    marginLeft: "auto",
  },
  signInButton: {
    marginLeft: "auto",
    color: theme.palette.secondary.light,
    // pointerEvents: "none",
  },
  accountButton: {
    fontSize: "200%"
  }
}));

function HeaderBar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [customerId, setCustomerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [uid, setUid] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    if (uid.length > 0) {
      axios.get('/api' + GET_CUST_ID + uid)
        .then((res) => {
          setCustomerId(res.data.arkeoCompanyId);
        })
        .catch((err) => console.error(err));
    }
  }, [uid]);

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      setIsSignedIn(true);
      setName(user.displayName);
      setEmail(user.email);
      setUid(user.uid);
      user.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setIsAdmin(true);
          }
        })
        .catch((error) => {
          setIsAdmin(false);
        });
    } else {
      setIsSignedIn(false);
    }
  });

  const handleSignInClick = () => {
    setAnchorEl(null);
    if(isSignedIn){
      firebase.analytics().logEvent('Logout',
        {
          email: email
        }
      )
      firebase.auth().signOut();
      setIsSignedIn(false);
    }
    props.history.push("/login")
  }

  const handleAccountClick = () => {
    setAnchorEl(null);
    props.handleActiveComponentChange("account");
    props.history.push("/")
  }

  const handleHomeClick = () => {
    setAnchorEl(null);
    props.handleActiveComponentChange("home");
    props.history.push("/");
  }

  const handleAdminClick = () => {
    setAnchorEl(null);
    props.history.push("/admin");
  }

  const handleQuoteClick = () => {
    setMenuAnchorEl(null);
    props.handleActiveComponentChange("quote");
    props.history.push("/");
  }

  const handleSettingsClick = () => {
    setMenuAnchorEl(null);
    props.handleActiveComponentChange("settings");
    props.history.push("/");
  }

  const handleCustomerClick = () => {
    setMenuAnchorEl(null);
    props.history.push("/customer/" + customerId);
  }

  return (
    <Grid className={classes.root} container direction="column" wrap="nowrap">
      <Grid item>
        <Toolbar className={classes.appBar}>
          <a href="/">
            <img
              src={process.env.PUBLIC_URL + '/royal-logo-noname.png'}
            />
          </a>
          <Grid container justify="flex-end" spacing={3} alignItems="center">
            {isSignedIn &&
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                {isAdmin && "Admin Account"}
                <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <AccountCircle className={classes.accountButton}/>
                </Button>
                <Hidden smUp>
                  <Button onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <MenuRoundedIcon className={classes.accountButton}/>
                  </Button>
                </Hidden>
              </div>
            </Grid>
            }
            {!isSignedIn &&
              <Grid item>
                <Button
                  className={classes.signInButton}
                  variant="outlined"
                  onClick={handleSignInClick}
                >
                  Sign In
                </Button>
              </Grid>
            }
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem disabled>{name}</MenuItem>
            {isAdmin && <MenuItem onClick={handleAdminClick}>Admin Settings</MenuItem>}
            <MenuItem onClick={handleHomeClick}>Home</MenuItem>
            <MenuItem onClick={handleAccountClick}>My Account</MenuItem>
            <MenuItem onClick={handleAccountClick}>Settings</MenuItem>
            <MenuItem onClick={handleSignInClick}>Sign Out</MenuItem>
          </Menu>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem onClick={handleCustomerClick}>Customer Overview</MenuItem>
            <MenuItem onClick={handleQuoteClick}>Quote Project</MenuItem>
            <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
          </Menu>
        </Toolbar>
      </Grid>
    </Grid>
  );
}

export default withRouter(HeaderBar);
