import React, { useEffect, useState } from 'react';
import {Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';
import firebase from 'firebase';

import AddAdminFromEmail from './AddAdminFromEmail';
import AddTeamAndPrimary from '../../static/AddTeamAndPrimary';
import EditContact from './EditContact';
import AdminHome from './AdminHome';

export default function Admin(){

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('/');

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                user.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!idTokenResult.claims.admin) {
                        setRedirectUrl("/");
                        setRedirect(true);
                    } else {
                        setRedirect(false);
                    }
                })
                .catch((error) => {
                    setRedirectUrl("/");
                    setRedirect(true);
                });
            }
            else{
                setRedirectUrl("/login");
                setRedirect(true);
            }
          });
    }, []);

    return(
        <>
        {redirect && <Redirect to={redirectUrl}/>}
        <BrowserRouter>
            <Switch>
                <Route exact path="/admin">
                    <AdminHome/>
                </Route>
                <Route path="/admin/addAdminFromEmail">
                    <AddAdminFromEmail/>
                </Route>
                <Route path='/admin/addTeamAndPrimaryContact'>
                    <AddTeamAndPrimary />
                </Route>
                <Route path="/admin/editContact">
                    <EditContact/>
                </Route>
            </Switch>
        </BrowserRouter>
        </>
    );
}
