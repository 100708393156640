import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';


import AddTeamMembers from './AddTeamMembers';
import Loading from '../common/Loading';

import{
    Grid,
    Button,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "90%",
      marginLeft: "5%",
      marginTop: "2%"
    },
    displayName: {
      marginLeft: 20,
      fontSize: 36,
      fontWeight: "bold",
    },
    avatar: {
      height: 50,
      width: 50,
      fontSize: 36,
    },
    sectionHeader: {
      fontSize: 32,
      marginTop: 10,
      marginBottom: 10,
    }
}));

const GET_TEAM_MEMBERS = '/getTeamMembers/';
const ADD_EMAIL_ALIAS = '/addEmailAlias';

export default function Account(){

    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [allMembers, setAllMembers] = useState([]);
    const [isPrimaryContact, setIsPrimaryContact] = useState(false);
    const [company, setCompany] = useState("");
    const [team, setTeam] = useState("");
    const [notificationPhone, setNotificationPhone] = useState("");
    const [notificationEmail, setNotificationEmail] = useState("");
    const [alias, setAlias] = useState("");


    const handleResetPassword = () => {
        var auth = firebase.auth();
        var user = auth.currentUser;
        firebase.analytics().logEvent('Reset Password',
          {
            email: user.email
          }
        );
        auth.sendPasswordResetEmail(user.email).then(function() {
            alert("A reset password link has been sent to your email. Please follow that link and sign-in again");
            auth.signOut();
        }).catch(function(error) {
            alert("There was an error sending the reset password link. Please try again in some time");
        });
    }

    const getTeamMembersTable = () => {
      axios.get('/api' + GET_TEAM_MEMBERS + firebase.auth().currentUser.uid)
      .then(res => {
          setAllMembers(res.data.members);
          setIsPrimaryContact(res.data.isPrimaryContact);
          setCompany(res.data.company);
          setTeam(res.data.teamId);
          setUser(firebase.auth().currentUser);
          firebase.analytics().logEvent('Account',
            {
              email: firebase.auth().currentUser.email
            }
          );
      })
      .catch(err => {
          console.log("get team members error");
      });
    }

    useEffect(() => {
        getTeamMembersTable();
    }, []);

    const addEmailAlias = () => {
      axios.post('/api' + ADD_EMAIL_ALIAS,
      {alias: alias, uid: firebase.auth().currentUser.uid})
      .then(res => {
        alert('Alias added successfully');
        setAlias("");
        getTeamMembersTable();
      })
      .catch(err => {
          console.log("add email alias error");
      })
    }

    return(
        <>
        {user &&
            <Grid container direction='column' spacing={3} className={classes.root}>
                <Grid item>
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <Avatar className={classes.avatar}>{user.displayName[0]}</Avatar>
                    <span className={classes.displayName}>{user.displayName}</span>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.sectionHeader}>Contact Information</div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Name"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={user.displayName}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={user.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Phone"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={notificationPhone ?? ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Company"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={company}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Team"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={team}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Notification Email"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={notificationEmail ?? ""}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.sectionHeader}>Email Aliases</div>
                  <Typography>Add email aliases</Typography>
                    <TextField
                      style={{marginTop: 10}}
                      label="Email Aliases"
                      variant="outlined"
                      fullWidth
                      value={alias}
                      onChange={(e)=>setAlias(e.target.value)}
                    />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginTop: 10}}
                    onClick={() => addEmailAlias()}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <div className={classes.sectionHeader}>Team Members</div>
                  <AddTeamMembers user={user} members={allMembers} isPrimaryContact={isPrimaryContact}/>
                </Grid>
                <Grid item>
                    <Button onClick={handleResetPassword} variant="outlined" color="secondary">Reset password</Button>
                </Grid>
                <Grid item></Grid>
            </Grid>
          }
        {!user && <Loading text="Loading Account..." /> }
        </>

    );
}
