import React, { useRef, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import{
    Grid,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';
import {
    Close
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    dropContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: "2%",
        width: "100%",
        // height: "200px",
        border: "1px solid #B99858",
        borderRadius: "20px",
        '&:hover': {
            cursor: "pointer"
        }
    },
    fileName: {
        // display: "inline-block",
        verticalAlign: "center",
        marginLeft: "50px",
        color: "#4aa1f3",
    },
    cloudIcon: {
        width: "60%",
        marginLeft: "20%",
        margin: "10%",
        // height: "60%",
        fontSize: "8em",
        color: "rgba(150,150,150,0.2)"
    },
    fileUploadBtn: {
        color: "white",
        textTransform: "uppercase",
        outline: "none",
        backgroundColor: "#4aa1f3",
        fontWeight: "bold",
        padding: "8px 15px",
        marginBottom: "5px",
    },
    fileInput: {
        display: "none"
    },
    fileStatusBar: {
        width: "100%",
        verticalAlign: "top",
        marginTop: "10px",
        marginBottom: "20px",
        position: "relative",
        lineHeight: "50px",
        height: "50px",
    },
    fileType: {
        display: "inline-block!important",
        position: "absolute",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "13px",
        marginTop: "12px",
        padding: "0 4px",
        borderRadius: "2px",
        boxShadow: "1px 1px 2px #abc",
        color: "#fff",
        background: "#0080c8",
        textTransform: "uppercase",
    },
    fileSize: {
        display:"inline-block",
        verticalAlign:"top",
        color:"#30693D",
        marginLeft:"10px",
        marginRight:"5px",
        fontWeight: "700",
        fontSize: "14px"
    },
    fileRemove: {
        lineHeight: "15px",
        cursor: "pointer",
        color: "red",
        marginRight: "-10px",
    }
}));

const Dropzone = (props) => {
    const defaultFile = (props.quoteData.uploadedFile !== undefined && props.quoteData.uploadedFile !== null) ? props.quoteData.uploadedFile : null;
    const classes = useStyles();
    const fileInputRef = useRef();
    const [selectedFile, setSelectedFile] = useState(defaultFile);

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const filesSelected = (e) => {
        if (fileInputRef.current.files.length) {
            handleFiles(e.target.files);
            e.target.value = ""
        }
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const handleFiles = (files) => {
        if(selectedFile !== null){
            alert("Only one file upload is allowed. Please remove the uploaded file and try again");
            return;
        }
        if(files.length > 1){
            alert("Please upload only one zipped file");
            return;
        }
        
        if (validateFile(files[0])) {
            setSelectedFile(files[0]);
        } else {
            alert("Please upload a zip file");
            return;
        }
    }

    const validateFile = (file) => {
        const validTypes = ['application/zip', 'application/gzip'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    }

    const fileSize = (size) => {
        if (size === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name) => {
        setSelectedFile(null);
    }

    const handleNext = () => {
        if(selectedFile === null){
            alert("Please upload a file");
            return;
        }
        props.handleNext({
            uploadedFile: selectedFile
        });
    }

    return (
        <React.Fragment>
            <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Typography className={classes.pageHeader}>Upload Project Files</Typography>
                </Grid>
                <Grid item>
                    <Typography>We accept the following files: <b>Gerbers & ODB++</b></Typography>
                </Grid>
                <Grid item>
                    <Grid container
                        direction={'column'}
                        className={classes.dropContainer}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={fileInputClicked}  
                    >
                        <Grid item xs={12}>
                            <FontAwesomeIcon icon={faUpload} className={classes.cloudIcon}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Drag & Drop files here or click to select file(s)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <input
                                ref={fileInputRef}
                                className={classes.fileInput}
                                type="file"
                                accept="application/zip, application/gzip"
                                multiple
                                onChange={(e) => filesSelected(e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography style={{color: "gray"}}>Using a different file type? Send an email to <b>sales@royalcircuits.com</b> and we'll help you out.</Typography>
                </Grid>
                { selectedFile !== null && 
                
                <Grid item>
                    <Grid container direction={'column'}>
                        <Grid item style={{fontWeight: "bold"}}>Selected File: </Grid>
                            <Grid item className={classes.fileStatusBar}>
                                <Grid container>
                                    <Grid item className={classes.fileType} xs={2}>
                                        {fileType(selectedFile.name)}
                                    </Grid>
                                    <Grid item className={classes.fileName} xs={6}>
                                        <Typography>{selectedFile.name} ({fileSize(selectedFile.size)})</Typography>
                                    </Grid>
                                    <Grid item className={classes.fileRemove} onClick={() => removeFile(selectedFile.name)} xs={1}>
                                        <Close/>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
                }
                <Grid item>
                    <Grid container justify="space-between" style={{marginTop: 30}}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={props.handleBack}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                color='secondary'
                                style={{color: "white"}}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Dropzone;