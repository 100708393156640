/* QuoteView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React from "react";
import {Redirect} from 'react-router-dom';
import {
  Grid,
  makeStyles,
  withStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EmailIcon from '@material-ui/icons/Email';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    height: "100%",
    backgroundColor: theme.palette.primary.light,
  },
  spacing: {
    margin: theme.spacing(2),
  }
}));

const StyledTooltip = withStyles({
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    color: "#555555",
    lineHeight: "1.7em",
    background: "#FFFFFF",
    border: "none",
    borderRadius: "3px",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: "250px",
    textAlign: "center",
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: "1.25em",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto"
  }
})(Tooltip);

interface SideBarProps {
  clickHome: () => void;
  clickStatus: () => void;
  clickQuote: () => void;
  clickEmail: () => void;
  clickAccount: () => void;
  clickSettings: () => void;
}

function SideBar({clickHome, clickStatus, clickQuote, clickEmail, clickAccount, clickSettings}:SideBarProps) {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState("");

  return (
    <Grid className={classes.root} alignItems="center" direction="column"  container>
      {redirect.length > 0 && <Redirect to={redirect} />}
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Home" placement="right">
          <HomeIcon
            style={{fontSize: 40, cursor: "pointer"}}
            onClick={clickHome}
          />
        </StyledTooltip>
      </Grid>
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Order Status" placement="right">
          <SearchIcon
            style={{fontSize: 40, cursor: "pointer"}}
            onClick={() => setRedirect('customer')}
          />
        </StyledTooltip>
      </Grid>
      <>
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Quote A Project" placement="right">
          <ShoppingCartIcon
            style={{fontSize: 40, cursor: "pointer"}}
            onClick={clickQuote}
          />
        </StyledTooltip>
      </Grid>
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Insta DFM Coming Soon!" placement="right">
          <CloudUploadIcon
            style={{fontSize: 40}}
            color="disabled"
          />
        </StyledTooltip>
      </Grid>
      </>
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Contact Us" placement="right">
          <EmailIcon
            style={{fontSize: 40, cursor: "pointer"}}
            onClick={clickEmail}
          />
        </StyledTooltip>
      </Grid>
      <>
      <Grid item className={classes.spacing}>
        <StyledTooltip title="Settings" placement="right">
          <SettingsIcon
            style={{fontSize: 40}}
            onClick={clickSettings}
          />
        </StyledTooltip>
      </Grid>
      </>
    </Grid>
  );
}

export default SideBar;
