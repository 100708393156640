import React, {useState, useEffect} from "react";
import axios from 'axios';
import {useParams, withRouter, Redirect, useLocation} from 'react-router-dom';
import firebase from 'firebase/app';
import "firebase/auth";
// import FileDownload from "js-file-download";
import {
    Divider,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Theme,
    Typography,
    TextField,
    Chip,
    Paper,
} from "@material-ui/core";

// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorPage from '../../static/ErrorPage'
import Lottie from 'react-lottie';
import animationData from "./lottie-circuit-light.json";
import animationDataDark from "./lottie-circuit-dark.json";
import { useTheme } from '@material-ui/core/styles';
// import OrderTimeLine from './OrderTimeLine';
// import DeliveryTimeLine from './DeliveryTimeLine';
// import OrderOverview from '../../common/OrderOverview';
import OrderPreview from "../../common/OrderPreview";
// import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
    body: {
      width: "80%",
      marginLeft: "10%",
      margin: theme.spacing(3),
      minHeight: "100vh",
    },
    container: {
      maxHeight: 750,
    },
    searching: {
      width: "80%",
      marginLeft: "10%",
      height: "100vh",
    },
    footer: {
      margin: theme.spacing(1),
      marginLeft: "auto",
    },
    note: {
      margin: theme.spacing(1),
      fontStyle: 'italic',
    },
    spacing: {
      margin: theme.spacing(1),
    },
    footer: {
      margin: theme.spacing(1),
      marginLeft: "auto",
    },
}));

const GET_CUSTOMER_STATUS_ENDPOINT = "/ordersByCustomer";
const GET_ORDERS_BY_SALES_REP_ENDPOINT = "/ordersBySalesRep";
const GET_CUSTOMER_STATUS_BY_NAME_ENDPOINT = "/ordersByCustomerName";
const GET_USER_INFO_ENDPOINT = "/getUserInfo";


// Main OrderStatus Component that calls other related components like TimeLine
function CustomerStatus(props){
    // state variables
    const [orderData, setOrderData]= useState([]);
    const [companyName, setCompanyName] = useState("");
    const [filteredOrderData, setFilteredOrderData] = useState([]);
    const [search, setSearch] = useState("");
    const [pageState, setPageState] = useState(-1);
    const [redirect, setRedirect] = useState(false);
    const [userInfo, setUserInfo] = useState({company: "", email: "", name: "", teamId: ""});
    const [currUrl, setCurrUrl] = useState(useLocation().pathname);
    const [limit, setLimit] = useState(100);
    const [customerId, setCustomerId] = useState(props.companyId ?? null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [customerSearch, setCustomerSearch] = useState("");
    const [customerError, setCustomerError] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const classes = useStyles();
    const theme = useTheme();

    const formatTime = (timestamp) => {
      return moment(timestamp).format("MMM D, YYYY");
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: theme.palette.type === 'dark' ?
                     animationDataDark : animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value);
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    // backend call to get order-status details
    // + `?custId=${uniqueCustomerId}&&uid=${user.uid}`
    const getOrderData = (uid) => {
      axios.post("/api" + GET_CUSTOMER_STATUS_ENDPOINT, {
        uid: uid,
        limit: 0
      })
      .then((res) => {
        setCompanyName(res.data.company);
        setOrderData(res.data.orders);
        setFilteredOrderData(res.data.orders);
        setPageState(1);
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    const getOrderDataByCustName = () => {
      setPageState(0);
      axios.post("/api" + GET_CUSTOMER_STATUS_BY_NAME_ENDPOINT, {
        admin: isAdmin,
        name: customerSearch,
      })
      .then((res) => {
        if (res.status === 204) {
          setCustomerError(true);
          setPageState(2);
          return;
        }
        setCompanyName(res.data.company);
        setOrderData(res.data.orders);
        setFilteredOrderData(res.data.orders);
        setPageState(1);
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })
    }

    const getOrdersBySalesRep = (user) => {
      axios.post("/api" + GET_ORDERS_BY_SALES_REP_ENDPOINT, {
        email: user.email,
      })
      .then((res) => {
        setCompanyName(res.data.company);
        setOrderData(res.data.orders);
        setFilteredOrderData(res.data.orders);
        setPageState(1);
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
      })

    }

    useEffect(() => {
      document.title = 'Customer Overview';
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          firebase.analytics().logEvent('Customer Overview',
            {
              email: user.email
            }
          );
          user.getIdTokenResult()
              .then((idTokenResult) => {
                if (!idTokenResult.claims.admin) {
                  setIsAdmin(false);
                  getOrderData(user.uid);
                } else {
                  setIsAdmin(true);
                  getOrdersBySalesRep(user);
                }
              })
              .catch((error) => {
                setRedirect(true);
                setIsAdmin(false);
              });
        }
        else {
          setIsAdmin(false);
          setRedirect(true);
        }
      });
    }, []);

    useEffect(() => {
      if (search === "") {
        setFilteredOrderData(orderData);
      }
      let filtered = orderData.filter((order) => {
        let lSearch = search.toLowerCase();
        return (
          order.ReferenceNumber.toLowerCase().includes(lSearch) ||
          order.PurchaseOrder.toLowerCase().includes(lSearch) ||
          order.PartNumber.toLowerCase().includes(lSearch) ||
          order.OrderStatus.toLowerCase().includes(lSearch)
        );
      });
      setFilteredOrderData(filtered);
    }, [search]);

    return(
      <div className={classes.body}>
      {redirect && <Redirect to={{
            pathname: "/login",
            state: { url: currUrl }
          }} />}
      {pageState === 0 && // loading state
        <Grid container justify="center" className={classes.searching}>
          <Grid item>
            <Lottie options={defaultOptions}
              height={400}
              width={400}
            />
          <h2 style={{textAlign: "center"}}>Loading...</h2>
          </Grid>
        </Grid>
      }
      { pageState === 1 && // data received
        <Grid className={classes.root}>
          {isAdmin && // admin search
            <TextField
              fullWidth
              variant="outlined"
              label="Customer Name"
              className={classes.customer}
              value={customerSearch}
              onChange={(e) => {
                setCustomerSearch(e.target.value);
                setCustomerError(false);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") getOrderDataByCustName();
              }}
              helperText={customerError ? "Invalid Customer Name" : ""}
              error={customerError}
            />
          }
          <Typography variant="h4" style={{fontWeight: 'bold'}}>
            Orders for {companyName}
          </Typography>
          <Typography paragraph>
            Use the search bar or chips to filter orders. The details button
            will route to the full order status page for any given order.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            helperText="Filter by Order #, Part #, or Status"
          />
          <Grid container spacing={2} className={classes.spacing}>
            <Grid item xs={6} md={3}>
              <Chip
                variant={search === "Processing" ? "default" : "outlined"}
                label="Processing"
                color="secondary"
                onClick={() => setSearch("Processing")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={search === "Released" ? "default" : "outlined"}
                label="In Production"
                color="secondary"
                onClick={() => setSearch("Released")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={search === "Shipped" ? "default" : "outlined"}
                label="Shipped"
                color="secondary"
                onClick={() => setSearch("Shipped")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={search === "On Hold" ? "default" : "outlined"}
                label="On Hold"
                color="primary"
                onClick={() => setSearch("On Hold")}
              />
            </Grid>
          </Grid>
          <Grid container>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Order No.</TableCell>
                    <TableCell>PO Number</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    filteredOrderData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order) =>
                      <OrderPreview order={order} key={order.ReferenceNumber}/>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredOrderData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={classes.footer}
            />

            <div className={classes.footer}>{filteredOrderData.length} results found</div>
          </Grid>
        </Grid>
      }
      {pageState === 2 && // invalid uniqueOrderId
        <ErrorPage errorCode={400} message={'There was an error. Please refresh the page.'}/>
      }
      </div>
    );
}

export default withRouter(CustomerStatus);
