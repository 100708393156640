import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    withStyles,
    Grid,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Card,
    CardContent
} from "@material-ui/core";

import {
    LocalShipping,
    House,
    Assignment,
    Input,
    HourglassEmptyRounded,
    PriorityHigh

} from '@material-ui/icons';

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//   },
//   stepper: {
//     backgroundColor: 'transparent'
//   }
// }));

// Styles for the TimeLine connector
const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
})(StepConnector);

// Styles for the TimeLine icons
const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(33,242,150) 0%, rgb(33,150,200) 50%, rgb(50,100,242) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
      'linear-gradient( 136deg, rgb(113,113,242) 0%, rgb(64,233,87) 50%, rgb(138,242,135) 100%)',
    },
    onHold: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    },
    cardShadow: {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);",
      margin: "25px 0px",
    },
    stepper: {
      backgroundColor: 'transparent'
    }
});

// Returns the appropriate icon for the step
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, error, icon, onHold } = props;

    const icons = {
        1: <HourglassEmptyRounded/>,
        2: <Assignment />,
        3: <Input />,
        4: <LocalShipping />,
        5: <House />
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active && !onHold,
          [classes.completed]: completed,
          [classes.onHold]: active && onHold
        })}
      >
        {onHold && active && <PriorityHigh/>}
        {onHold && !active && icons[String(icon)]}
        {!onHold && icons[String(icon)]}
      </div>
    );
}

// Gets all Step names. Seperated for easy edits (if needed later)
function getStepNames(onHold, orderStatus) {
  if (onHold) {
    if(orderStatus === 'Active')
      return ['Order Placed','DFM Check & Engineering (On Hold)', 'In Production', 'Order Shipped', 'Order Arrived'];
    if(orderStatus === 'Released')
      return ['Order Placed','DFM Check & Engineering', 'In Production (On Hold)', 'Order Shipped', 'Order Arrived'];
  }
    return ['Order Placed','DFM Check & Engineering', 'In Production', 'Order Shipped', 'Order Arrived'];
}

// Gets step number from the OrderStatus passed as a prop by Main to TimeLine...
// TODO add more cases based on what is returned from the api call in Main
function getStepFromOrderStatus(orderStatus){
  var statuses = ['Processing', '','Released','Shipped', 'Delivered'];
  var i = statuses.indexOf(orderStatus);
  return (i === -1) ? 0 : i;
}

// Returns the TimeLine component
function OrderTimeLine(props){
    const classes = useColorlibStepIconStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getStepNames(props.holdStatus === 'OnHold', props.orderStatus);

    // Runs only during the first component mount, or when props.orderStatus changes
    useEffect(() => {
      setActiveStep(getStepFromOrderStatus(props.orderStatus));
      if (props.holdStatus === 'OnHold') {
        if(props.orderStatus === 'Active')
          setActiveStep(1);
        if(props.orderStatus === 'Released')
          setActiveStep(2);
      }
    }, [props.orderStatus]);

    return(
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.cardShadow}>
              <CardContent>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={() => ColorlibStepIcon({
                        active: (index === activeStep),
                        completed: (index  < activeStep),
                        error: false,
                        icon: index+1,
                        onHold: (props.holdStatus === 'OnHold')
                      })}>{label}</StepLabel>
                  </Step>
                  ))}
                </Stepper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    );
}

export default OrderTimeLine;
