import React, {useState} from 'react';

import {
    Grid, 
    TextField,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import { CallMissedSharp, SettingsSystemDaydream } from '@material-ui/icons';

const GET_USER_INFO_FROM_EMAIL = "/getUserInfoFromEmail";

const EDIT_USER_TEAM_AND_COMPANY= "/editUserTeamIdCompany";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "5%",
        marginTop: "3%",
        height: "80vh"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
}))

export default function EditContact(){

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState("");
    const [teamId, setTeamId] = useState("");
    
    
    
    const handleSearchClick = () => {
        axios.get("/api" + GET_USER_INFO_FROM_EMAIL + `/${email}`)
        .then(res => {
            setUser(res.data);
            setCompany(res.data.company);
            setTeamId(res.data.teamId)
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    const handleSave = () => {
        if(company === "" || teamId === ""){
            alert("company or team id cannot be empty");
            return;
        }
        if(company.includes("/") || teamId.includes("/")){
            alert("company and team id cannot contain the '/' character");
            return;
        }
        if(company === user.company && teamId === user.teamId){
            return;
        }
        axios.post("/api" + EDIT_USER_TEAM_AND_COMPANY, {
            uid: user.uid,
            teamId: teamId,
            company: company
        })
        .then(res => {
            alert("successfully updated the user team id and company");
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    return(
        <div className={classes.root}>
        <Grid container direction='column' spacing={7}>
            <Grid item>
                <Typography className={classes.pageHeader}>Edit user company / team</Typography>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        id="search"
                        name="search"
                        label="Enter email to search for the user"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button color='primary' variant='contained' onClick={handleSearchClick}>Search</Button>
                </Grid>
            </Grid>
            {user !== null &&
            <Grid container item xs={12} direction='column' spacing={3}>
                <Grid item xs={6}>
                    <TextField 
                        id='company'
                        name="company"
                        label="Company"
                        variant="outlined"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        id='teamId'
                        name="teamId"
                        label="Team ID"
                        variant="outlined"
                        value={teamId}
                        onChange={(e) => setTeamId(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button color='primary' variant='contained' onClick={handleSave}>Save</Button>
                </Grid>
            </Grid>
            }
        </Grid>
        </div>
    );
}