import React, {useState, useEffect} from "react";
import axios from 'axios';
import {useParams, withRouter, Redirect, useLocation} from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
// import FileDownload from "js-file-download";
import {
    Grid,
    makeStyles,
    Theme,
    Card,
    CardContent,
    Divider,
    Typography,
    useMediaQuery,
} from "@material-ui/core";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Lottie from 'react-lottie';
import animationData from "./lottie-circuit-light.json";
import animationDataDark from "./lottie-circuit-dark.json";
import { useTheme } from '@material-ui/core/styles';
import OrderTimeLine from './OrderTimeLine';
import MobileOrderTimeLine from './MobileOrderTimeLine';
import DeliveryTimeLine from './DeliveryTimeLine';
import OrderOverview from '../../common/OrderOverview';
import OrderOverviewSCC from '../../common/OrderOverviewSCC';
import ErrorPage from '../../static/ErrorPage';
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      overflowX: 'hidden'
    },
    body: {
      width: "80%",
      marginLeft: "10%",
      margin: theme.spacing(3),
      minHeight: "80vh"
    },
    searching: {
      width: "80%",
      marginLeft: "10%",
      height: "100vh",
    },
    refNum: {
      fontSize: "2em",
      fontWeight: "bold",
    },
    card: {
      borderRadius: "10px",
      marginTop: "1%",
      title: {
        fontWeight: "bold"
      }
    },
    cardSubtitle: {
      fontSize: 22,
      fontWeight: "bold"
    },
    cardContent: {
      fontSize: 16,
    },
    vl: {
      borderLeft: "1px solid black",
      height: "40px",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "50%"
    },
    projectName: {
      fontSize: "2em",
      marginTop: "2%",
    },
    deliveryTimeLine: {
      marginTop: "2%",
      width: "30%"
    },
    loading: {
      width: "30%",
      marginLeft: "35%"
    },
    circularLoader: {
      width: "100%",
      color: "secondary",
    },
    labels: {
        fontSize: 22,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
}));


// Main OrderStatus Component that calls other related components like TimeLine
function OrderStatus(props){

    const GET_ORDER_STATUS_ENDPOINT = "/orderDetailsByUniqueId";

    const isMobile = !useMediaQuery('(min-width:600px)');

    // state variables
    const [company, setCompany] = useState("RCS");
    const [orderData, setOrderData]= useState({});
    const [redirect, setRedirect] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [holdStatus, setHoldStatus] = useState("");
    const [projectName, setProjectName] = useState("");
    const [currUrl, setCurrUrl] = useState(useLocation().pathname);
    const [projectRev, setProjectRev] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [pageState, setPageState] = useState(-1); // 0 - pulling data | 1 - received valid data | 2 - error
    const [arrivalDate, setArrivalDate] = useState("");
    const [trackingInfo, setTrackingInfo] = useState({});
    const [deliveryTitle, setDeliveryTitle] = useState("Expected Ship Date");
    const [showTrackingDetails, setShowTrackingDetails] = useState(false);
    const classes = useStyles();
    const theme = useTheme();


    let { uniqueOrderId } = useParams();

    const formatTime = (timestamp) => {
      console.log(timestamp);
      return moment(timestamp).format("MMM D, YYYY");
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: theme.palette.type === 'dark' ?
                     animationDataDark : animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const handleSetState = (data) => {
      if(data.ReferenceNumber === undefined || data.ReferenceNumber.length === 0){
        setPageState(2);
        return;
      }
      setOrderData(data);
      setReferenceNumber(data.ReferenceNumber);
      setOrderStatus(data.OrderStatus);
      setHoldStatus(data.HoldStatus);
      setProjectName(data.PartNumber);
      setProjectRev(data.PartRev);
      setOrderDate(data.OrderDate.split("T")[0]);
      setArrivalDate(formatTime(data.DueDate));
      if(data.OrderStatus === "Shipped" && data.Shipments !== undefined && data.Shipments.length > 0){
        if (data.Shipments[0].Method === "RCS") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 0)) {
            setDeliveryTitle("Delivered by Driver")
            setArrivalDate(formatTime(data.Shipments[0].Date));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle("Out For Delivery By Driver")
            setArrivalDate(formatTime(data.Shipments[0].Date));
          }
        }
        if (data.Shipments[0].Method === "Southwest") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 6)) {
            setDeliveryTitle("Delivered by Southwest")
            setArrivalDate(formatTime(data.Shipments[0].Date));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle("Out For Delivery By Southwest")
            setArrivalDate(formatTime(data.Shipments[0].Date));
          }
        }
        if (Object.keys(data.Shipments[0].TrackingInfo).length !== 0) {
          setShowTrackingDetails(true);
          var trackingInfo = data.Shipments[0].TrackingInfo;

          if(trackingInfo.status === "delivered"){
            setDeliveryTitle("Delivered on");
            var deliveryDate = trackingInfo.tracking_details[trackingInfo.tracking_details.length-1].datetime.split("T")[0];
            setArrivalDate(formatTime(deliveryDate));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle(`Shipped via ${trackingInfo.carrier}`);
            setArrivalDate(`${trackingInfo.tracking_code}`)
          }
          setTrackingInfo(trackingInfo);
        }
      }
      setPageState(1);
    }

    const handleSetSCCState = (data) => {
      if(data.ReferenceNumber === undefined || data.ReferenceNumber.length === 0){
        setPageState(2);
        return;
      }
      setOrderData(data);
      setReferenceNumber(data.ReferenceNumber);
      setOrderStatus(data.OrderStatus);
      setHoldStatus(data.HoldStatus);
      setProjectName(data.ProjectName);
      setProjectRev(data.PartRev);
      setOrderDate(data.OrderDate * 1000);
      setArrivalDate(formatTime(data.DueDate));
      if(data.OrderStatus === "Completed") {
        setDeliveryTitle("Delivered")
        setOrderStatus("Delivered");
      }
      if(data.OrderStatus === "Shipped" && data.Shipments !== undefined && data.Shipments.length > 0){
        if (data.Shipments[0].Method === "RCS") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 0)) {
            setDeliveryTitle("Delivered by Driver")
            setArrivalDate(formatTime(data.Shipments[0].Date));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle("Out For Delivery By Driver")
            setArrivalDate(formatTime(data.Shipments[0].Date));
          }
        }
        if (data.Shipments[0].Method === "Southwest") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 6)) {
            setDeliveryTitle("Delivered by Southwest")
            setArrivalDate(formatTime(data.Shipments[0].Date));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle("Out For Delivery By Southwest")
            setArrivalDate(formatTime(data.Shipments[0].Date));
          }
        }
        if (Object.keys(data.Shipments[0].TrackingInfo).length !== 0) {
          setShowTrackingDetails(true);
          var trackingInfo = data.Shipments[0].TrackingInfo;

          if(trackingInfo.status === "delivered"){
            setDeliveryTitle("Delivered on");
            var deliveryDate = trackingInfo.tracking_details[trackingInfo.tracking_details.length-1].datetime.split("T")[0];
            setArrivalDate(formatTime(deliveryDate));
            setOrderStatus("Delivered");
          } else {
            setDeliveryTitle(`Shipped via ${trackingInfo.carrier}`);
            setArrivalDate(`${trackingInfo.tracking_code}`)
          }
          setTrackingInfo(trackingInfo);
        }
      }
      setPageState(1);
    }

    const arrivedDaysAgo = (date, days) => {
      let today = moment();
      return today.diff(moment(date), 'days') > days;
    };

    // backend call to get order-status details
    const getOrderData = (user) => {
      setPageState(0);
      axios.post("/api" + GET_ORDER_STATUS_ENDPOINT, {
        uniqueOrderId: uniqueOrderId,
        uid: user.uid
      })
      .then((res) => {
        setCompany(res.data.company);
        var data = res.data.order;
        if (data.ReferenceNumber) {
          if (data.OrderStatus !== "Shipped") {
            firebase.analytics().logEvent('Order Status Active', {email: user.email, orderId: data.ReferenceNumber});
          } else {
            firebase.analytics().logEvent('Order Status Shipped', {email: user.email, orderId: data.ReferenceNumber});
          }
          document.title = `Order #${data.ReferenceNumber}`;
        } else {
          firebase.analytics().logEvent('Order Status', {email: user.email, orderId: 'Not Found'});
          document.title = 'Order Not Found';
        }
        if (res.data.company === 'RCS') {
          handleSetState(data);
        } else {
          handleSetSCCState(data);
        }
      })
      .catch((err) => {
        console.log("err status", err)
        if(err.response && err.response.status === 403){
          setPageState(3);
        }
        else{
          setPageState(2);
        }
      })
    }

    // below 'useEffect' runs only when the component is mounted for the first time, not on component updates. Remove '[]' to run everytime...
    useEffect(() => {
      firebase.auth().onAuthStateChanged(function(currUser) {
          if (currUser) {
            if(props.location.state !== undefined){
                var data = props.location.state.data;
                document.title = 'Order ' + (data.ReferenceNumber ?
                                            '#' + data.ReferenceNumber :
                                            "Not Found");

                handleSetState(data);
            }
            else {
                getOrderData(currUser);
            }
          } else {
              setRedirect(true);
          }
      });
    }, []);

    return(
      <>
      {redirect && <Redirect to={{
            pathname: "/login",
            state: { url: currUrl }
          }} />}
      <div className={classes.body}>
      {pageState === 0 && // loading state
        <Grid container justify="center" className={classes.searching}>
          <Grid item>
            <Lottie options={defaultOptions}
              height={400}
              width={400}
            />
          <h2 style={{textAlign: "center"}}>Searching for your order...</h2>
          </Grid>
        </Grid>
      }
      { pageState === 1 && // data received
        <Grid className={classes.root}>
          <Grid container>
            <Grid item>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <CheckCircleOutlineIcon style={{fontSize: 36, color: 'green'}} />
                <span className={classes.refNum}>Order No. {referenceNumber}</span>
              </div>
            </Grid>
            { isMobile ?
              <MobileOrderTimeLine orderStatus={orderStatus} holdStatus={holdStatus}/>
              :
              <OrderTimeLine orderStatus={orderStatus} holdStatus={holdStatus}/>
            }
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography className={classes.labels}>
                        Project Name
                      </Typography>
                      <Typography>
                        {projectName} / {projectRev}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography className={classes.labels}>
                        PO Number
                      </Typography>
                      <Typography>
                        {orderData.PurchaseOrder}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography className={classes.labels}>
                        Order Placed
                      </Typography>
                      <Typography>
                        {formatTime(orderDate)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Card>
                    <CardContent>
                      <Typography className={classes.labels}>
                        {deliveryTitle}
                      </Typography>
                      <Typography>
                        {arrivalDate}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {showTrackingDetails && <Grid item xs={12}>
              <div className={classes.deliveryTimeLine}>
                <h2>Shipment Details</h2>
                <h3>Shipped via {trackingInfo.carrier}</h3>
                <h3>Tracking: {trackingInfo.tracking_code}</h3>
                <DeliveryTimeLine
                  steps={trackingInfo.tracking_details}
                />
              </div>
            </Grid>}
          </Grid>
          { company === 'RCS' ?
            <OrderOverview data={orderData}/> :
            <OrderOverviewSCC data={orderData}/>
          }
        </Grid>
      }
      {pageState === 2 && // invalid uniqueOrderId
        <ErrorPage
          errorCode={400}
          message={`We couldn't find an order with the provided unique id
            '${uniqueOrderId}'. Please try again, or send an email to
            support@royalcircuits.com to get the correct ID for your order(s).`
          }
        />
      }
      {pageState === 3 && // unauthorized
        <ErrorPage errorCode={403} message={"Sorry, you do not have access to this page, or the resource doesn't exist"}/>
      }
      </div>
      </>
    );
}

export default withRouter(OrderStatus);
