import React, {useState} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import axios from 'axios';

import {
    Button,
    Theme,
    Grid,
    TextField,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
}));

function OrderStatusSearch(props){

    const GET_ORDER_STATUS_ENDPOINT = '/orderDetailsByReferenceNumber';

    const classes = useStyles();
    const [orderId, setOrderId] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [componentState, setComponentState] = useState(0);
    const [redirectData, setRedirectData] = useState({});
    const [hasError, setHasError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");

    const handleOrderIdKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleUniqueIdSubmit();
        }
    }

    const handleCustomerIdKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleUniqueCompanyIdSubmit();
        }
    }

    const handleUniqueIdSubmit = () => {
      if(orderId === ""){
        setHasError(true);
        setErrorText("Please enter an ID");
      } else {
        setRedirectUrl("/orderStatus/" + orderId);
      }
    }

    const handleUniqueCompanyIdSubmit = () => {
      if (companyId === "") {
        setHasError(true);
        setErrorText("Please enter an ID");
      } else {
        setRedirectUrl("/customer/" + companyId);
      }
    }

    const handleReferenceNumberSubmit = () => {
        if(referenceNumber === "" || partNumber === ""){
            setHasError(true);
            setErrorText("Please enter a reference number and part number");
        }
        else{
            axios.post("/api" + GET_ORDER_STATUS_ENDPOINT, {
                orderReferenceNumber: referenceNumber,
                orderPartNumber: partNumber
            })
            .then((res) => {
                var data = res.data;
                props.history.push({
                    pathname: "/orderStatus/" + data.SID,
                    state: { data: data }
                })
            })
            .catch((err) => {
                console.log(err.response.data);
                setHasError(true);
                setErrorText(err.response.data);
            })
        }
    }

    return(
        <Grid container spacing={2} justify="center" alignItems="center">
            {componentState === 0 &&
                <>
                {redirectUrl !== "" && <Redirect push to={redirectUrl} />}
                <Grid item xs={10}>
                    <TextField fullWidth error={hasError && orderId.length === 0} helperText={orderId.length === 0 && errorText} className={classes.textField} onKeyDown={handleOrderIdKeyDown} onChange={e => setOrderId(e.target.value)} id="standard-basic" label="Unique Order ID"/>
                </Grid>
                <Grid item xs={10}>
                  <Grid container justify="space-evenly" spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Button color="primary" variant="contained" onClick={handleUniqueIdSubmit}>Submit</Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button color="secondary" variant="outlined" onClick={() => setComponentState(1)}>I forgot my Unique Order ID</Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button color="secondary" variant="contained" onClick={() => setComponentState(2)}>I have my Unique Company ID</Button>
                    </Grid>
                  </Grid>
                </Grid>
                </>
            }
            {componentState === 1 &&
                <>
                {redirectUrl !== "" && <Redirect push to={{
                    pathname: redirectUrl,
                    state: { data: redirectData }
                }} />}
                <Grid item xs={12}>
                    <TextField error={hasError && referenceNumber.length === 0} className={classes.textField} onChange={e => setReferenceNumber(e.target.value)} id="standard-basic" label="Order Reference Number"/>
                </Grid>
                <Grid item xs={12}>
                    <TextField error={hasError && partNumber.length === 0} helperText={partNumber.length === 0 && errorText} className={classes.textField} onChange={e => setPartNumber(e.target.value)} id="standard-basic" label="Part Number"/>
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" variant="contained" className={classes.submitButton} onClick={handleReferenceNumberSubmit}>Submit</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button color="secondary" variant="outlined" className={classes.changeSearchTypeButton} onClick={() => setComponentState(0)}>Search using Unique Order ID</Button>
                </Grid>
                </>
            }
            {componentState === 2 &&
                <>
                {redirectUrl !== "" && <Redirect push to={redirectUrl} />}
                <Grid item xs={12}>
                    <TextField error={hasError && companyId.length === 0} helperText={companyId.length === 0 && errorText} className={classes.textField} onKeyDown={handleCustomerIdKeyDown} onChange={e => setCompanyId(e.target.value)} id="standard-basic" label="Enter your unique company ID"/>
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" variant="contained" onClick={handleUniqueCompanyIdSubmit} className={classes.submitButton}>Submit</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button color="secondary" variant="outlined" className={classes.changeSearchTypeButton} onClick={() => setComponentState(0)}>I have my Unique Order ID</Button>
                </Grid>
                </>
            }
        </Grid>
    );
}

export default withRouter(OrderStatusSearch);
