import React from "react";
import axios from 'axios';

import {
    Typography,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";

import {
    GetApp
} from '@material-ui/icons';

import SpecificationsTable from './SpecificationsTable';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: "5%",
        marginBottom: "5%"
    },
    titles: {
        fontSize: "2em",
        marginLeft: theme.spacing(1),
        fontWeight: "bold",
    },
    labels: {
        fontSize: 22,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
    RelevantFilesContainer: {
        paddingLeft: "1%",
        marginTop: "2%"
    },
    RelevantFilesCard: {
    },
    cardHeader: {
      fontSize: "0.5em"
    },
    card: {
      margin: theme.spacing(1),
    },
    bottomAsterisk: {
      marginTop: "2%",
      fontSize: "0.9em",
    }
}));

export default function OrderOverview(props){

    const GET_FILE_ENDPOINT = "/file";

    var orderData = props.data;
    var classes = useStyles();

    const currencyFormat = (num) => {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    };

    const handleFileDownloadClick = () => {
        let config = {
          url: '/api' + GET_FILE_ENDPOINT + "?filename=" + orderData.BoardDataFileAzure,
          method: 'get',
          headers: {
            'content-type':'application/json;charset=UTF-8'
          },
          responseType: 'blob'
        };
        axios(config)
        .then(response => {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', orderData.BoardDataFile);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => console.error(err));
    }

    return (
      <Grid container className={classes.root} direction={'column'} spacing={5}>
        <Grid item xs={12}>
          <Typography className={classes.titles}>
            Order Overview
          </Typography>
        </Grid>
        {/* horizontal container for 4 cards */}
        <Grid item xs={12}>

          <Grid container spacing={3}>

            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <Typography className={classes.labels}>
                    Quantity
                  </Typography>
                  <Typography>
                    {orderData.OrderQuantity}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <Typography className={classes.labels}>
                    Lead Time
                  </Typography>
                  <Typography>
                    {orderData.Leadtime} day{orderData.Leadtime > 1 && 's'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <Typography className={classes.labels}>
                    Unit Price
                  </Typography>
                  <Typography>
                    {
                      !orderData.UnitPrice || orderData.UnitPrice === 0 ?
                      currencyFormat(orderData.TotalCost / orderData.OrderQuantity) :
                      currencyFormat(orderData.UnitPrice)
                    }
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Card>
                  <CardContent>
                    <Typography className={classes.labels}>
                      Total Price
                    </Typography>
                    <Typography>
                      {currencyFormat(orderData.TotalCost)}
                    </Typography>
                  </CardContent>
                </Card>
            </Grid>

          </Grid>

        </Grid>

        {/* Shipping address */}
        <Grid item xs={12} style={{marginBottom: "2%"}}>
          <Card>
            <CardContent>
              <Typography className={classes.labels}>
                Shipping Address
              </Typography>
              <Typography>
                {orderData.ShippingName.trim()}
              </Typography>
              {orderData.ShippingAttention.length > 0 &&
                <Typography>
                  {orderData.ShippingAttention.trim()}
                </Typography>
              }
              <Typography>
                {orderData.ShippingAddress1.trim()}
              </Typography>
              {orderData.ShippingAddress2.length > 0 &&
                <Typography>
                {orderData.ShippingAddress2.trim()}
                </Typography>
              }
              <Typography>
                {orderData.ShippingAddressCity.trim()}, {orderData.ShippingAddressStateShortName.trim()} {orderData.ShippingAddressZipCode.trim()}
              </Typography>
              <Typography>
                {orderData.ShippingAddressCountryName.trim()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Specifications Table */}
        <Grid item xs={12}>
          <SpecificationsTable data={orderData}/>
        </Grid>

        <Grid item xs={12} className={classes.RelevantFilesContainer}>
          <Grid container direction={'column'} spacing={3}>
            <Grid item xs={12}>
              <Card className={classes.RelevantFilesCard}>
                  <CardContent>
                  <Typography className={classes.labels}>
                    Relevant files
                  </Typography>
                    {orderData.BoardDataFile !== "" &&
                      <>
                        <Grid container>
                          <Grid item xs={8} style={{fontWeight: "bold", fontSize: 18}}>
                              Filename
                          </Grid>
                          <Grid container item xs={4} justify="flex-end" style={{fontWeight: "bold", fontSize: 18}}>
                              Download
                          </Grid>
                        </Grid>
                        <Divider style={{margin: "10px 0px"}}/>
                        <Grid container>
                            <Grid item xs={8} style={{fontWeight: "bold"}}>
                                {orderData.BoardDataFile}
                            </Grid>
                            {!orderData.IsItar &&
                            <Grid container item xs={4} justify="flex-end">
                                <Button disabled onClick={handleFileDownloadClick}><GetApp style={{fontSize: 24}}/></Button>
                            </Grid>
                            }
                            {orderData.IsItar &&
                              <Typography>
                              ITAR files are not available for download. Please contact your Sales Rep to get the relevant files.
                              </Typography>
                            }
                        </Grid>
                        </>
                    }
                    {orderData.BoardDataFile === "" &&
                      <Typography>
                        No files found
                      </Typography>
                    }
                    <Typography className={classes.bottomAsterisk}>
                      *All files are stored in a secure, ITAR-compliant encrypted server in the cloud.
                    </Typography>
                  </CardContent>
              </Card>
            </Grid>
            <Grid>
              {/* <Typography className={classes.bottomAsterisk}>
                *All files are stored in a secure, ITAR-compliant encrypted server in the cloud.
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    );
}
