import React from "react";

import {
    Grid,
    Typography,
    TextField,
    makeStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button
} from "@material-ui/core";

import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';


import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
    pageHeader: {
        fontSize: 32,
        fontWeight: "bold",
        paddingBottom: 10,
    },
    paymentsCardEntry: {
        border: "1px solid gray",
        borderRadius: "10px",
        padding: "20px"
    },
    submitOrderButton: {

    }
}))

const cardStyle = {
    iconStyle: "solid",
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
};


export default function ShippingAndPayments(props){

    const stripe = useStripe();
    const elements = useElements();


    const classes = useStyles();
    const parentHandleNext = props.handleNext;
    const quoteData = props.quoteData;

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = yup.object({
        shippingName: yup
            .string('Enter the shipping name')
            .required('A shipping name is required'),
        shippingAddressPhone: yup
            .string().matches(phoneRegExp, 'Enter the shipping address phone number'),    
        shippingStreetAddress: yup
            .string('Enter your Street Address for Shipping')
            .required('Street is required'),
        shippingApartmentNumber: yup
        .string('Enter your Apartment / Suite / Other'),
        shippingCity: yup
            .string('Enter your City')
            .required('City is required'),
        shippingZipCode: yup
            .string('Enter your Zip Code')
            .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits')
            .required('Zip Code is required'),
        shippingState: yup
            .string('Enter your State')
            .required('State is required'),
        nameOnCard: yup
            .string('Enter the name on card')
            .required('Name on card is required'),
        isBillingAddressSame: yup
            .string(),
        billingStreetAddress: yup
            .string('Enter your Street Address for Billing')
            .when('isBillingAddressSame',{
                is: 'false',
                then: yup
                        .string('Enter your Billing Street Address')
                        .required('Billing Street Address is required when the Billing Address is different from the Shipping Address'),
                otherwise: yup
                            .string('Enter your Billing Street Address'),
            }),
        billingApartmentNumber: yup
            .string('Enter your Apartment / Suite / Other'),
        billingCity: yup
            .string('Enter your City for Billing')
            .when('isBillingAddressSame',{
                is: 'false',
                then: yup
                        .string('Enter your City for Billing')
                        .required('Billing City is required when the Billing Address is different from the Shipping Address'),
                otherwise: yup
                            .string('Enter your Billing City'),
            }),
        billingZipCode: yup
            .string('Enter your City for Billing')
            .when('isBillingAddressSame',{
                is: 'false',
                then: yup
                        .string('Enter your Zip Code for Billing')
                        .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits')
                        .required('Billing Zip Code is required when the Billing Address is different from the Shipping Address'),
                otherwise: yup
                            .string('Enter your Zip Code for Billing')
                            .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits'),
            }),
        billingState: yup
            .string('Enter your State for Billing')
            .when('isBillingAddressSame',{
                is: 'false',
                then: yup
                        .string('Enter your State for Billing')
                        .required('Billing State is required when the Billing Address is different from the Shipping Address'),
                otherwise: yup
                            .string('Enter your State for Billing'),
            }),

        
    });

    var initialValues = {
        shippingName: '',
        shippingAddressPhone: '',
        shippingStreetAddress: '',
        shippingApartmentNumber: '',
        shippingCity: '',
        shippingZipCode: '',
        shippingState: '',
        nameOnCard: '',
        billingStreetAddress: '',
        billingApartmentNumber: '',
        billingCity: '',
        billingZipCode: '',
        billingState: '',
        isBillingAddressSame: 'true',
    };
    if(quoteData.shippingStreetAddress !== undefined && quoteData.shippingStreetAddress !== ''){
        initialValues.shippingName = quoteData.shippingName;
        initialValues.shippingAddressPhone = quoteData.shippingAddressPhone;
        initialValues.shippingStreetAddress = quoteData.shippingStreetAddress;
        initialValues.shippingApartmentNumber = quoteData.shippingApartmentNumber;
        initialValues.shippingCity = quoteData.shippingCity;
        initialValues.shippingZipCode = quoteData.shippingZipCode;
        initialValues.shippingState = quoteData.shippingState;
        initialValues.nameOnCard = quoteData.nameOnCard;
        initialValues.isBillingAddressSame = quoteData.isBillingAddressSame;
        if(quoteData.isBillingAddressSame === 'false'){
            initialValues.billingStreetAddress = quoteData.billingStreetAddress;
            initialValues.billingApartmentNumber = quoteData.billingApartmentNumber;
            initialValues.billingCity = quoteData.billingCity;
            initialValues.billingZipCode = quoteData.billingZipCode;
            initialValues.billingState = quoteData.billingState;
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleNext(values);
        },
    });


    const handleNext = async (values) => {
        var address = {}; // address variable supplies billing address to stripe
        if(formik.values.isBillingAddressSame === 'true'){
            address.line1 = formik.values.shippingStreetAddress;
            address.city = formik.values.shippingCity;
            address.state = formik.values.shippingState;
            address.postal_code = formik.values.shippingZipCode;
        }
        else{
            address.line1 = formik.values.billingStreetAddress;
            address.city = formik.values.billingCity;
            address.state = formik.values.billingState;
            address.postal_code = formik.values.billingZipCode;
        }
        
        const {error, paymentMethod} = await stripe.createPaymentMethod({  // verifies card details
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                name: formik.values.nameOnCard,
                address: address
            }
        })
        if(!error){ // if no error, saves card id and goes to next quote page
            try{
                const {id} = paymentMethod;
                values['stripeCardId'] = id;
                parentHandleNext(values);
            } catch(err){
                console.log("err", err);
            }
        }
        else{
            alert("There was some problem verifying the card. Please check your card details and try again");
        }
    }

    return(
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction={'column'} spacing={5}>
                {/* Page Title */}
                <Grid item>
                    <Typography className={classes.pageHeader}>Shipping Information</Typography>
                </Grid>
                
                {/* Shpping Address */}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: "bold", fontSize: "1.5em"}}>Shipping Address</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingName"
                            name="shippingName"
                            label="Name *"
                            variant="outlined"
                            value={formik.values.shippingName}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingName && Boolean(formik.errors.shippingName)}
                            helperText={formik.touched.shippingName && formik.errors.shippingName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingAddressPhone"
                            name="shippingAddressPhone"
                            label="Shipping Contact Number "
                            variant="outlined"
                            value={formik.values.shippingAddressPhone}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingAddressPhone && Boolean(formik.errors.shippingAddressPhone)}
                            helperText={formik.touched.shippingAddressPhone && formik.errors.shippingAddressPhone}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="shippingStreetAddress"
                            name="shippingStreetAddress"
                            label="Street Address *"
                            variant="outlined"
                            value={formik.values.shippingStreetAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingStreetAddress && Boolean(formik.errors.shippingStreetAddress)}
                            helperText={formik.touched.shippingStreetAddress && formik.errors.shippingStreetAddress}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingApartmentNumber"
                            name="shippingApartmentNumber"
                            label="Apartment Number / Suite / Other (Optional)"
                            variant="outlined"
                            value={formik.values.shippingApartmentNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingApartmentNumber && Boolean(formik.errors.shippingApartmentNumber)}
                            helperText={formik.touched.shippingApartmentNumber && formik.errors.shippingApartmentNumber}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingCity"
                            name="shippingCity"
                            label="City *"
                            variant="outlined"
                            value={formik.values.shippingCity}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingCity && Boolean(formik.errors.shippingCity)}
                            helperText={formik.touched.shippingCity && formik.errors.shippingCity}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingZipCode"
                            name="shippingZipCode"
                            label="Zip Code *"
                            variant="outlined"
                            value={formik.values.shippingZipCode}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingZipCode && Boolean(formik.errors.shippingZipCode)}
                            helperText={formik.touched.shippingZipCode && formik.errors.shippingZipCode}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="shippingState"
                            name="shippingState"
                            label="State *"
                            variant="outlined"
                            value={formik.values.shippingState}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingState && Boolean(formik.errors.shippingState)}
                            helperText={formik.touched.shippingState && formik.errors.shippingState}
                            fullWidth
                        />
                    </Grid>
                    </Grid>
                </Grid>
                
                {/* Name on Card and Card Details */}
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography style={{fontWeight: "bold", fontSize: "1.5em"}}>Payment Methods</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="nameOnCard"
                                name="nameOnCard"
                                label="Name on Card *"
                                variant="outlined"
                                value={formik.values.nameOnCard}
                                onChange={formik.handleChange}
                                error={formik.touched.nameOnCard && Boolean(formik.errors.nameOnCard)}
                                helperText={formik.touched.nameOnCard && formik.errors.nameOnCard}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardElement options={cardStyle} className={classes.paymentsCardEntry}/>
                        </Grid>
                    </Grid>
                </Grid>
                
                {/* Billing Address */}
                <Grid item style={{marginTop: "1%"}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography style={{fontWeight: "bold", fontSize: "1.5em"}}>Billing Address</Typography>
                        </Grid>
                        <Grid item xs={6} justify="center">
                            <RadioGroup 
                                row
                                id="isBillingAddressSame"
                                name="isBillingAddressSame"
                                value={formik.values.isBillingAddressSame} 
                                onChange={formik.handleChange}
                            >
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <FormControlLabel name="isBillingAddressSame" value={"true"} control={<Radio />} label="Same as Shipping Address"/>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel name="isBillingAddressSame" value={"false"} control={<Radio />} label="Different from Shipping Address"/>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        {formik.values.isBillingAddressSame === 'false' &&
                            <Grid item>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                <TextField
                                    id="billingStreetAddress"
                                    name="billingStreetAddress"
                                    label="Street Address*"
                                    value={formik.values.billingStreetAddress}
                                    variant="outlined"
                                    error={formik.touched.billingStreetAddress && Boolean(formik.errors.billingStreetAddress)}
                                    helperText={formik.touched.billingStreetAddress && formik.errors.billingStreetAddress}
                                    onChange={formik.handleChange}
                                    fullWidth
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    id="billingApartmentNumber"
                                    name="billingApartmentNumber"
                                    label="Apartment Number / Suite / Other (Optional)"
                                    variant="outlined"
                                    value={formik.values.billingApartmentNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.billingApartmentNumber && Boolean(formik.errors.billingApartmentNumber)}
                                    helperText={formik.touched.billingApartmentNumber && formik.errors.billingApartmentNumber}
                                    fullWidth
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    id="billingCity"
                                    name="billingCity"
                                    label="City*"
                                    variant="outlined"
                                    value={formik.values.billingCity}
                                    onChange={formik.handleChange}
                                    error={formik.touched.billingCity && Boolean(formik.errors.billingCity)}
                                    helperText={formik.touched.billingCity && formik.errors.billingCity}
                                    fullWidth
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    id="billingZipCode"
                                    name="billingZipCode"
                                    label="Zip Code*"
                                    variant="outlined"
                                    value={formik.values.billingZipCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.billingZipCode && Boolean(formik.errors.billingZipCode)}
                                    helperText={formik.touched.billingZipCode && formik.errors.billingZipCode}
                                    fullWidth
                                />
                                </Grid>
                                <Grid item xs={6}>
                                <TextField
                                    id="billingState"
                                    name="billingState"
                                    label="State*"
                                    variant="outlined"
                                    value={formik.values.billingState}
                                    onChange={formik.handleChange}
                                    error={formik.touched.billingState && Boolean(formik.errors.billingState)}
                                    helperText={formik.touched.billingState && formik.errors.billingState}
                                    fullWidth
                                />
                                </Grid>
                            </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                
                {/* Back and Next Buttons */}
                <Grid item>
                    <Grid container justify="space-between" style={{marginTop: 30}}>
                        <Grid item>
                            <Button
                            variant="contained"
                            onClick={props.handleBack}
                            >
                            Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                            variant="contained"
                            onClick={formik.handleSubmit}
                            color='primary'
                            style={{color: "white"}}
                            >
                            Review Order
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}