import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';
import {
    makeStyles
} from "@material-ui/core";

import {Redirect} from 'react-router-dom';

const IS_ADMIN = "/isAdmin/";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: "10%",
        marginBottom: "20%"
    }
}));

export default function Login(props){

    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [url, setUrl] = useState("/");


    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: url,
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // Whether the display name should be displayed in Sign Up page.
            requireDisplayName: true,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
            disableSignUp: {
              status: true
            }
          }
        ],
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                // const userId = currentUser.uid;
                redirectUrl = url;
                firebase.analytics().logEvent('Login',
                  {
                    email: authResult.user.email
                  }
                )
                return false
            },
        }
    };

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(currUser) {
            if (currUser) {
              setUser(currUser);
            }
        });
        if(props.location.state !== undefined){
            setUrl(props.location.state.url)
        }
    }, [])



    return (
        <div>
            {user &&
                <Redirect to={url}/>
            }

            { !user &&
            <div className={classes.root}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
            }
        </div>
    );
}
