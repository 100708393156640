import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  StepButton
} from '@material-ui/core';
import clsx from 'clsx';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faClipboardList, faCloudUploadAlt, faCreditCard, faCheckDouble, faClipboardCheck} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    backgroundColor: 'transparent',
    fontSize: "1.2em"
  },
  iconContainer: {
    transform: 'scale(1.2)',
  }
}));



function getSteps() {
  return ['Project Basics', 'Pricing', 'Upload Files', 'Shipping and Payment Information', 'Order Review', 'Order Confirmation'];
}

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    display: 'flex',
    height: 23,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.dark,
    fontSize: "1.2em",
    fontWeight: "bold"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.light,
    zIndex: 1,
    fontSize: 18,
  },
  icon: {
    fontSize: "1.5em"
  }
}));

export default function QuoteTimeLine(props) {
  const classes = useStyles();
  const steps = getSteps();

  const handleClick = (index) => {
    props.setStep(index);
  }

  function StepIcon(props){
    const classes = useStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: <FontAwesomeIcon icon={faClipboardList} className={classes.icon}/>,
      2: <FontAwesomeIcon icon={faDollarSign} className={classes.icon}/>,
      3: <FontAwesomeIcon icon={faCloudUploadAlt} className={classes.icon}/>,
      4: <FontAwesomeIcon icon={faCreditCard} className={classes.icon}/>,
      5: <FontAwesomeIcon icon={faClipboardCheck} className={classes.icon}/>,
      6: <FontAwesomeIcon icon={faCheckDouble} className={classes.icon}/>,
    };
  
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed
        })}
      >
        {/* {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />} */}
        {icons[String(props.icon)]}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={props.step} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={() => handleClick(index)}>
              <StepLabel classes={{ // apply this style
            iconContainer: classes.iconContainer
          }} StepIconComponent={StepIcon}>{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}