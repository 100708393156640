/* App.tsx
 * This file renders the BomBuilder application. Some overall components are
 * initialized here, like the SnackbarProvider, BackendHealth, and others.
 * There are also hotkeys to upload a file of packages / druid locks.
 * We can see the endpoints are routed into components here, such that the
 * index endpoint (/) renders <CreateProject />, and a specific job endpoint
 * (/job/<job_id>) renders <JobView />
 */

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, Theme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";


import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID
} from './constants';

//components
// import FileUploadView from "./FileUploadView";
import QuoteView from "./components/QuoteView/QuoteView";
import DashboardView from "./DashboardView";
import HeaderBar from "./static/HeaderBar";
import FooterBar from "./static/FooterBar";
import OrderStatus from "./components/OrderStatus/OrderStatus";
import CustomerStatus from "./components/OrderStatus/CustomerStatus";
import Login from "./static/Login";
import Register from "./static/Register";
// import Account from "./static/Account";
import AddTeamAndPrimary from './static/AddTeamAndPrimary';
import Admin from './components/Admin/Admin';

require('dotenv').config()

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const perf = firebase.performance();

function App() {

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [activeComponent, setActiveComponent] = React.useState("home");

  const theme: Theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            light: "#668675",
            main: "#365445",
            dark: "#09271A",
          },
          secondary: {
            light: "#F3CD8B",
            main: "#B99758",
            dark: "#816428",
          },
        },
      }),
    [prefersDarkMode]
  );

  const handleActiveComponentChange = (component) => {
    setActiveComponent(component);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        maxSnack={2}
      >
        <BrowserRouter>
        <HeaderBar handleActiveComponentChange={handleActiveComponentChange}/>
          <Switch>
            <Route exact path={["/","/orderStatus/"]}>
              <DashboardView handleActiveComponentChange={handleActiveComponentChange} activeComponent={activeComponent}/>
            </Route>
            <Route path="/quote">
              <QuoteView />
            </Route>
            <Route path="/login" render={(props) => <Login {...props}/>} />
            <Route path="/orderStatus/:uniqueOrderId">
              <OrderStatus />
            </Route>
            <Route path="/customer/">
              <CustomerStatus />
            </Route>
            <Route path="/register/:emailId/:senderUid">
              <Register />
            </Route>
            <Route path="/addTeamAndPrimaryContact">
              <AddTeamAndPrimary />
            </Route>
            <Route path="/admin">
              <Admin/>
            </Route>
          </Switch>
        </BrowserRouter>
        <FooterBar />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
