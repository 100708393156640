import React from 'react';

import {
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    Typography,
    StepContent,
    Card,
    CardContent,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 600,
      maxWidth: 800,
      overflowY: "scroll",
      maxHeight: 300,
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));

  export default function DeliveryTimeLine(props) {
    const classes = useStyles();

    return (
      <Card className={classes.root}>
        <CardContent>
          <Stepper activeStep={props.steps.length-1} orientation="vertical">
            {props.steps.slice(0).reverse().map((step, index) => (
              <Step key={index} expanded={true}>
                <StepLabel>
                  {step.datetime.split("T")[0]}
                </StepLabel>
                <StepContent>
                  <Typography>{step.message}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    );
  }
