import React, {useState, useEffect} from 'react';

import {
    Grid,
    makeStyles,
    Button,
    Card,
    Typography
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "5%",
        marginTop: "3%"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    sectionHeader: {
        fontWeight: "bold",
        fontSize: "1.5em"
    }
}));

export default function AdminHome(props){

    const classes = useStyles();

    const [redirectUrl, setRedirectUrl] = useState("/");
    const [redirect, setRedirect] = useState(false);
    const [admins, setAdmins] = useState([]);
    const GET_ALL_ADMINS = '/getAllAdmins';

    useEffect(() => {
      axios.get('/api' + GET_ALL_ADMINS)
        .then((res) => {
          if (res.status === 200) {
            setAdmins(res.data);
          }
        })
        .catch((err) => console.error(err));
    },[]);

    const handleAddAdminClick = () => {
        setRedirectUrl("/admin/addAdminFromEmail");
        setRedirect(true);
    }

    const handleEditContactClick = () => {
        setRedirectUrl("/admin/editContact");
        setRedirect(true);
    }

    const handleAddTeamPrimaryClick = () => {
        setRedirectUrl("/admin/addTeamAndPrimaryContact");
        setRedirect(true);
    }

    const listAllAdmins = () => {
      return admins.map((user) => {
        return (
          <Card style={{margin: 10, padding: 10}}>
            <Typography variant="h5" style={{fontWeight: "bold"}}>
              {user.displayName}
            </Typography>
            <Typography>Email: {user.email}</Typography>
            <Typography>UID: {user.uid}</Typography>
          </Card>
        );
      });
    }

    return(
        <>
        {redirect && <Redirect push to={redirectUrl} />}
        <Grid container spacing={2} justify="space-evenly">
          <Grid item>
            <Grid container className={classes.root} direction='column' spacing={7}>
                <Grid item>
                    <Typography className={classes.pageHeader}>
                        Admin Home
                    </Typography>
                </Grid>
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography className={classes.sectionHeader}>Add admin</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Add an already registered user as an admin of the portal</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleAddAdminClick}
                        color='primary'
                        variant='contained'
                        >
                            Add admin page
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography className={classes.sectionHeader}>Edit Contact Team and Company</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Change the company or team of an already existing user</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleEditContactClick}
                        color='primary'
                        variant='contained'
                        >
                            Edit team page
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography className={classes.sectionHeader}>Add new Team and Primary Contact</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Add a new team / company and primary contact</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleAddTeamPrimaryClick}
                        color='primary'
                        variant='contained'
                        >
                            Add Team and Primary page
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container className={classes.root} direction='column' spacing={7}>
              <Grid item>
                <Typography className={classes.pageHeader}>
                  Current Admins
                </Typography>
              </Grid>
              <Grid item>
                {listAllAdmins()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        </>
    );
}
