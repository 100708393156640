import React from "react";

import SpecificationsTable from '../../common/SpecificationsTable';

import axios from 'axios';

import {
    Grid,
    Typography,
    CardContent,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Card,
    Button,
    makeStyles
} from "@material-ui/core";

// endpoints
const CONFIRM_PAYMENT_ENDPOINT = "/registerPayment";
const CREATE_ORDER_IN_ARKEO_ENDPOINT = "/saveQuote";
const UPLOAD_FILE_ENDPOINT = "/attachFileToOrder";

const useStyles = makeStyles((theme: Theme) => ({
    orderProgressRoot: {
        marginTop: "1%",
        width: "100%",
        backgroundColor: "#F4F4F4",
        paddingBottom: 50,
    },
    orderProgressHeading: {
        fontSize: "2em",
        fontWeight: "bold",
        margin: "1%"
    },
    capabilities: {
        marginTop: theme.spacing(2),
    },
    quoteOverview: {
        marginLeft: "5%"
    },
    orderProgressTitle: {
        fontWeight: "bold",
        fontSize: "1.1em"
    },
    progressLabels: {
        fontWeight: "bold"
    },
    spec: {
        fontWeight: "bold",
    },
    
}));

const GetOrderProgress = (props) => {
    const classes = useStyles();
    const step = props.step;
    const data = props.data;
    if(data === undefined || data === null)  return(<div></div>);
    const tableData = convertDataToOrderOverviewFormat(data);
    return (
      <div className={classes.orderProgressRoot}>
        {step === 0 && 
          <Capabilities/>
        }
        {step > 0 &&
          <QuoteOverview step={props.step} tableData={tableData} data={data} quoteData={props.quoteData} handleNext={props.handleNext} handleBack={props.handleBack}/>
        }
      </div>
    )
}

const QuoteOverview = (props) => {
    const tableData = props.tableData;
    const quoteData = props.quoteData;
    // const parentHandleNext = props.handleNext;
    const step = props.step;
    const classes = useStyles();
    const cardsData = [{
        label: "Quantity",
        value: quoteData.quantity
      },
      {
        label: "Lead Time",
        value: quoteData.leadTime
      },
      {
        label: "Unit Price",
        value: 3 // TODO: value to be changed
      },
      {
        label: "Total Price",
        value: '$' + quoteData.price
      }
    ];


    const handleConfirmOrderClick =  (quoteData) => {
      const amount = quoteData.price*100;
      // const formData = new FormData();
      // formData.append('file', quoteData.uploadedFile);
      // formData.append('quoteData', quoteData);
      axios.post("/api" + CONFIRM_PAYMENT_ENDPOINT, {
          quoteData
      })
      .then((confirmOrderResponse) => {
            const formData = new FormData();
            formData.append('file', quoteData.uploadedFile);
            formData.append('orderId', confirmOrderResponse.data.orderId);
            const config = {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          }
            axios.post("/api" + UPLOAD_FILE_ENDPOINT, formData, config)
            .then(fileUploadResponse => {
              
            })
            .catch(fileUploadError => {
              console.log("file upload error", fileUploadError)
            })
      })
      .catch((confirmPaymentError) => {
          alert("There was a problem in authorizing your card, please check the card details and try again.");
          console.log("payment error", confirmPaymentError.response);
          return;
      })

      props.handleNext({
        quoteConfirmationSuccess: true
      });

    }

    return (
      <Grid item xs={12} className={classes.quoteOverview}>
        <Grid container direction={'column'} spacing={3}>
          <Grid item>
            <Typography className={classes.orderProgressHeading}>Your Quote #0001</Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container justify="space-between">
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.orderProgressTitle}>Email Address: </Typography>
                    <Typography>{quoteData.email}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.orderProgressTitle}>Part Number: </Typography>
                    <Typography>{quoteData.projName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {step === 4 &&
              <Grid item>
                <Button
                  onClick={props.handleBack}
                  variant="contained"
                  color='primary'
                  style={{color: "white"}}
                >
                  Edit Order
                </Button>
              </Grid>
              }
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={10}>
              <Grid item xs={12} lg={3}>
                <Grid container direction={'column'} spacing={2}>
                  {cardsData.map((card, i) => (
                    <Grid item xs={12} key={card.label}>
                    <Card>
                      <CardContent>
                        <Typography className={classes.progressLabels}>
                          {card.label}:
                        </Typography>
                        <Typography>
                          {card.value}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={8}>
                <SpecificationsTable data={tableData}/>
              </Grid>
            </Grid>
          </Grid>
          { step >= 3 && 
            <Grid item>
              <Typography className={classes.orderProgressTitle}>Uploaded Files:</Typography>
              <Typography>{quoteData.uploadedFile.name}</Typography>
            </Grid>
          }
          { step >= 4 && 
            <React.Fragment>
              <Grid item>
                <Typography className={classes.orderProgressTitle}>Shipping Address: </Typography>
                <Typography>{quoteData.shippingStreetAddress}</Typography>
                {quoteData.shippingApartmentNumber !== "" && 
                  <Typography>{quoteData.shippingApartmentNumber}</Typography>
                }
                <Typography>{quoteData.shippingCity}</Typography>
                <Typography>{quoteData.shippingZipCide}</Typography>
                <Typography>{quoteData.shippingState}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.orderProgressTitle}>Name on Card: </Typography>
                <Typography>{quoteData.nameOnCard}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.orderProgressTitle}>Billing Address: </Typography>
                {quoteData.isBillingAddressSame === "true" ?
                  <Typography>Same as Shipping Address</Typography>
                  : 
                  <>
                  <Typography>{quoteData.billingStreetAddress}</Typography>
                  <Typography>{quoteData.billingApartmentNumber!== "" ? quoteData.billingApartmentNumber : ""}</Typography>
                  <Typography>{quoteData.billingCity}</Typography>
                  <Typography>{quoteData.billingZipCide}</Typography>
                  <Typography>{quoteData.billingState}</Typography>
                  </>
                }  
              </Grid>
              {step === 4 && 
              <Grid item>
                <Button 
                  onClick={() => handleConfirmOrderClick(quoteData)}
                  variant="contained"
                  color='secondary'
                  style={{color: "white"}}
                >
                  Confirm Order
                </Button>
              </Grid>
              }
            </React.Fragment>
          }
        </Grid>
      </Grid>
    );
}

// Seperated static Capabilities section
const Capabilities = () => {
    const classes = useStyles();
    return (
    <Grid item xs={12} >
      {/* <div style={{marginTop: 20, marginLeft: theme.spacing(3)}}> */}
      <Grid container spacing={3} alignItems="flex-start"
            justify="space-evenly" className={classes.capabilities}>
        <Typography paragraph style={{fontSize: 35, fontWeight: "bold"}}>
          Review Our Capabilities
        </Typography>
        </Grid>
      {/* </div> */}
      <Grid container spacing={3} alignItems="flex-start"
            justify="space-evenly" className={classes.capabilities}>
        <Grid item xs={4}>
          <Typography paragraph>
            All online orders must fall within our standard capabilities.
            Have a more complex design, or want your boards faster? Send
            an email with your files to <span style={{fontWeight: "bold"}}>
          sales@royalcircuits.com</span> and we'll quote it ASAP!
          </Typography>
          <Typography paragraph>
            All orders placed after 10am PST will be considered to start
            the next business day. Our business days are Monday - Friday,
            not including major US holidays.
          </Typography>
          <Typography paragraph style={{fontWeight: "bold"}}>
          If you have any questions, please call (831)-636-7789, or send
          an email to support@royalcircuits.com
          </Typography>
          <Typography paragraph>
            Order pricing does not include taxes and shipping where
            applicable.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Standard Spec</TableCell>
                  <TableCell>Custom Capabilities</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableRows}>
                <TableRow>
                  <TableCell className={classes.spec}>Layer Count</TableCell>
                  <TableCell className={classes.val}>1-6 Layers</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Turn Time</TableCell>
                  <TableCell className={classes.val}>5+ Days</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Quantity</TableCell>
                  <TableCell className={classes.val}>5-200 PCBs</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Material</TableCell>
                  <TableCell className={classes.val}>FR4 Tg 180</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Surface Finish</TableCell>
                  <TableCell className={classes.val}>ENIG</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Certification</TableCell>
                  <TableCell className={classes.val}>IPC Class 2</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Board Thickness</TableCell>
                  <TableCell className={classes.val}>62 Mils</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Solder Mask</TableCell>
                  <TableCell className={classes.val}>Green</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Silk Screen</TableCell>
                  <TableCell className={classes.val}>White</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Copper Weight</TableCell>
                  <TableCell className={classes.val}>up to 2 oz</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Min Trace / Space</TableCell>
                  <TableCell className={classes.val}>6/6 mils</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.spec}>Min Hole Size</TableCell>
                  <TableCell className={classes.val}>8 mils</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
    );
}

function convertDataToOrderOverviewFormat(oldFormat){
    var newFormat = {
      NumLayers: oldFormat.layers,
      SilkColor: oldFormat.silk,
      BoardSize: oldFormat.boardLength + " x " + oldFormat.boardWidth + " inches",
      CopperWeightInnerName: oldFormat.innerCopper,
      CopperWeightOuterName: oldFormat.outerCopper,
      BoardThickness: oldFormat.boardThickness,
      SurfaceFinish: oldFormat.surfaceFinish,
      IsItar: oldFormat.itar,
      Material: oldFormat.material,
      MaskColor: oldFormat.mask,
      MinSapce: 0
    }
    return newFormat;
}

export default GetOrderProgress;