import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';

import {useParams, Redirect} from 'react-router-dom';

const CREATE_NEW_INVITED_USER = '/createInvitedUser';

export default function Register(){

    let {emailId, senderUid} = useParams()
    console.log("from ostsignin", emailId, senderUid);

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        ],
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                redirectUrl = '/';
                if(authResult.additionalUserInfo.isNewUser){
                    console.log("new user", authResult, emailId)
                    axios.post('/api' + CREATE_NEW_INVITED_USER, {
                        senderUid: senderUid,
                        inviteEmailId: emailId,
                        signInEmailId: authResult.user.email,
                        InvitedUid: authResult.user.uid,
                        InvitedName: authResult.user.displayName
                    })
                    .then(res => {

                    })
                    .catch(err => {

                    })
                }
                return false;
            },
        }
    };

    const [user, setUser] = React.useState(null);

    firebase.auth().onAuthStateChanged(function(currUser) {
        if (currUser) {
          setUser(currUser);
        }
    });

    return (
        <div>
            {user &&
                <Redirect to="/"/>
            }
            {!user &&
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            }
        </div>
    );
}