import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import moment from "moment";
import CustomerStatus from '../components/OrderStatus/CustomerStatus';
import ErrorPage from './ErrorPage';
import OrderTimeLine from '../components/OrderStatus/OrderTimeLine';
import MobileOrderTimeLine from '../components/OrderStatus/MobileOrderTimeLine';
import OrderPreview from '../common/OrderPreview';
import Loading from '../common/Loading';

import {
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Theme,
    TextField,
    Typography,
    Chip,
    Paper,
    Button,
    useMediaQuery,
    CircularProgress
} from '@material-ui/core';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';

const GET_CUSTOMER_STATUS_ENDPOINT = "/ordersByCustomer";

const useStyles = makeStyles((theme: Theme) => ({
    orderTimeline: {
        borderRadius: "30px"
    },
    container: {
        maxHeight: 750,
    },
}));

export default function Home(){
    const LIMIT = 5;
    const classes = useStyles();
    const isMobile = !useMediaQuery('(min-width:600px)');

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [company, setCompany] = useState("");
    const [pageState, setPageState] = useState(0);
    const [mostRecentOrderStatus, setMostRecentOrderStatus] = useState("");
    const [ordersData, setOrdersData] = useState(null);

    const getRecentOrders = (uid) => {
      axios.post("/api" + GET_CUSTOMER_STATUS_ENDPOINT, {
        uid: uid,
        limit: LIMIT
      })
      .then(res => {
        console.log(res);
        setCompany(res.data.company);
        setOrdersData(res.data.orders);
        setPageState(1);
      })
      .catch(err => {
        setPageState(2);
      })
    }

    useEffect(() => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          getRecentOrders(user.uid);
        } else {
          setRedirectUrl("/login");
          setRedirect(true);
        }
      });
    }, []);

    useEffect(() => {
      if(ordersData === undefined || ordersData === null || ordersData.length === 0){
        return;
      }
      var data = ordersData[0];
      setMostRecentOrderStatus(data.OrderStatus);
      if(data.OrderStatus === "Shipped" && data.Shipments !== undefined && data.Shipments.length > 0){
        if (data.Shipments[0].Method === "RCS") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 0)) {
            setMostRecentOrderStatus("Delivered");
          }
        }
        if (data.Shipments[0].Method === "Southwest") {
          if (arrivedDaysAgo(data.Shipments[0].Date, 6)) {
            setMostRecentOrderStatus("Delivered");
          }
        }
        if (data.Shipments[0].TrackingInfo !== undefined && Object.keys(data.Shipments[0].TrackingInfo).length !== 0) {
          var trackingInfo = data.Shipments[0].TrackingInfo;
          if(trackingInfo.status === "delivered"){
            setMostRecentOrderStatus("Delivered");
          }
        }
      }
    }, [ordersData]);

    const arrivedDaysAgo = (date, days) => {
        let today = moment();
        return today.diff(moment(date), 'days') > days;
    };

    const handleSeeAllOrders = () => {
        setRedirectUrl("/customer");
        setRedirect(true);
    }

    const handleOrderDetails = () => {
      window.open("/orderStatus/" + ordersData[0].SID, "_blank")
    }

    return (
      <>
        {redirect && <Redirect to={redirectUrl} />}
        {pageState === 1 &&
          <Grid container alignItems="center" justify="center">
            <Grid item xs={10}>
              {ordersData && ordersData.length > 0 &&
                <>
                <div style={{ marginTop: 25, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                  <span>
                    <Typography
                      variant='h5'
                      style={{fontWeight: "bold"}}
                    >
                      Order No. {ordersData[0].ReferenceNumber}
                    </Typography>
                  </span>
                  <LaunchRoundedIcon
                    style={{marginLeft: 8, fontSize: 28, cursor: 'pointer'}}
                    onClick={handleOrderDetails}
                  />
                </div>
                  {isMobile ?
                    <MobileOrderTimeLine
                      orderStatus={mostRecentOrderStatus}
                      holdStatus={ordersData[0].HoldStatus}
                    /> :
                    <OrderTimeLine
                      className={classes.orderTimeline}
                      orderStatus={mostRecentOrderStatus}
                      holdStatus={ordersData[0].HoldStatus}
                    />
                  }
                </>
              }
            </Grid>
            <Grid item xs={10}>
                  <Grid container spacing={2} direction='column'>
                      <Grid item xs={12}>
                        <Typography
                          variant='h5'
                          style={{fontWeight: "bold", marginTop: 15}}
                        >
                          Recent Active Orders
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <TableContainer component={Paper} className={classes.container}>
                          <Table stickyHeader>
                              <TableHead>
                              <TableRow>
                                  <TableCell>Order No.</TableCell>
                                  <TableCell>PO Number</TableCell>
                                  <TableCell>Part Number</TableCell>
                                  <TableCell>Order Date</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Details</TableCell>
                              </TableRow>
                              </TableHead>
                              <TableBody>
                              {
                                  ordersData.map((order) =>
                                  <OrderPreview order={order} key={order}/>
                                  )
                              }
                              </TableBody>
                          </Table>
                          </TableContainer>
                      </Grid>
                      <Grid item>
                          <Button color='secondary' onClick={handleSeeAllOrders}>See all orders</Button>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
        }
        {pageState === 2 &&
        <ErrorPage errorCode={500} message={"There was a problem getting your recent orders, please refresh the page."} />
      }
        {pageState === 0 && <Loading text="Loading Dashboard..." />
      }
      </>
    );
}
