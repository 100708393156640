import React from "react";

import {
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    Typography,
    makeStyles,
    Card,
    CardContent
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderRadius: "20px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    TableLabel: {
        fontWeight: "bold"
    },
    cardTitle: {
        fontSize: 28,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    }
}));

export default function SpecificationsTable(props){
    var data = props.data;
    var classes = useStyles();
    return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.cardTitle}>
          Design Specs
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography className={classes.TableLabel}>
                      Layers
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.NumLayers}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.TableLabel}>
                      Silkscreen Color
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.SilkColor}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Dimensions
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.BoardSize}
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Copper Weight
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.CopperWeightInnerName} / {data.CopperWeightOuterName}
                  </Typography>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Min Trace/Space
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {(data.MinSpace * 1000).toFixed(0)} mils
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Surface Finish
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.SurfaceFinish}
                  </Typography>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      ITAR
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.IsItar === false ? "No" : "Yes"}
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Material
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.Material}
                  </Typography>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Board Thickness
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.BoardThickness * 1000} mils
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableLabel}>
                      Soldermask Color
                  </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className={classes.TableValue}>
                      {data.MaskColor}
                  </Typography>
                  </TableCell>
              </TableRow>
              <TableRow>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
    )
}
