import React from "react";
import moment from "moment";
import {
    TableCell,
    TableRow,
    makeStyles,
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 500,
        margin: theme.spacing(1),
    },
    titles: {
        fontSize: "2em",
        marginLeft: theme.spacing(1),
        fontWeight: "bold",
    },
    labels: {
        fontSize: 28,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
    RelevantFilesContainer: {
        paddingLeft: "1%"
    },
    RelevantFilesCard: {
    },
    cardHeader: {
      fontSize: "0.5em"
    },
    card: {
      margin: theme.spacing(1),
    },
    bottomAsterisk: {
      marginTop: "2%",
      fontSize: "0.9em",
    },
    holdRow: {
      outline: 'none',
      borderColor: 'red',
      boxShadow: '0 0 15px red',
    },
}));

export default function OrderPreview(props){

    var orderData = props.order;
    // var partRev = orderData.PartRev !== "-" ? 'Rev: ' + orderData.PartRev : ""
    var classes = useStyles();

    // const currencyFormat = (num) => {
    //   return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // };

    const formatTime = (timestamp) => {
      if (typeof(timestamp) === "number")
        timestamp*=1000;
      return moment(timestamp).format("MMM D, YYYY");
    }

    const handleOrderDetails = () => {
      window.open("/orderStatus/" + orderData.SID, "_blank")
    }

    return (
      <TableRow
        className={orderData.OrderStatus === 'On Hold' ? classes.holdRow : classes.aRow}
      >
        <TableCell>
          {orderData.ReferenceNumber}
        </TableCell>
        <TableCell>
          {orderData.PurchaseOrder}
        </TableCell>
        <TableCell>
          {orderData.PartNumber}
        </TableCell>
        <TableCell>
          {formatTime(orderData.OrderDate)}
        </TableCell>
        <TableCell>
          {orderData.OrderStatus}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOrderDetails}
          >
            Details
          </Button>
        </TableCell>
      </TableRow>
    );
}
