import React, {useState} from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import "firebase/auth";

import {
    Grid,
    TextField,
    Button,
    Card,
    makeStyles,
    Tooltip,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const ADD_TEAM_MEMBER = '/addTeamMember';
const REMOVE_TEAM_MEMBER = '/removeTeamMember';
const CHANGE_TEAM_PRIMARY = '/changePrimaryContact';

const useStyles = makeStyles((theme: Theme) => ({
    memberNames: {
        marginLeft: "5%",
    },
    addMember: {
        marginLeft: "10%"
    },
    container: {
      maxHeight: 500,
    },
}));


export default function AddTeamMembers(props){

    var classes = useStyles();

    const [memberEmail, setMemberEmail] = useState('');
    const [allMembers, setAllMembers] = useState(props.members);
    const [isPrimaryContact, setIsPrimaryContact] = useState(props.isPrimaryContact);
    const [currUser, setCurrUser] = useState(props.user);

    React.useEffect(() => {
      setAllMembers(props.members);
    }, [props.members]);

    const handleAddMemberClick = () => {
      firebase.analytics().logEvent('Add Member',
        {
          email: firebase.auth().currentUser.email
        }
      );
      axios.post('/api' + ADD_TEAM_MEMBER, {
          emailId: memberEmail,
          senderUid: currUser.uid,
          senderName: currUser.displayName,
          senderEmail: currUser.email
      })
      .then(res => {
          alert("An invitation email has been sent to the requested email");
          setMemberEmail("");
      })
      .catch(err => {
          if(err.response){
              alert(err.response.data)
          }
      })
    }

    const handleAddMemberTextFieldChange = (e) => {
        setMemberEmail(e.target.value);
    }

    const handleRemoveMember = (index) => {
        if(window.confirm("Are you sure you want to remove the member?")){
            axios.post('/api' + REMOVE_TEAM_MEMBER, {
                removeMemberEmailId: allMembers[index].email,
                requesterUid: currUser.uid,
                requesterEmail: currUser.email
            })
            .then(res => {
              alert('Team member removed successfully!')
            })
            .catch(err => {
                if(err.response){
                    alert(err.response.data)
                }
            })
        }
    }

    const handleChangePrimary = (index) => {
        if(window.confirm("Are you sure you want to make this member the team primary contact?")){
            axios.post('/api' + CHANGE_TEAM_PRIMARY, {
                requesterUid: currUser.uid,
                newPrimaryEmail: allMembers[index].email
            })
            .then(res => {
                setIsPrimaryContact(false);
                alert(res.data)
            })
            .catch(err => {
                if(err.response){
                    alert(err.response.data)
                }
            })
        }
    }

    return(
      <Grid container spacing={1} direction='column'>
            <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Aliases</TableCell>
                    <TableCell>Primary Contact</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {allMembers.map((member, index) => {
                          return (
                            <TableRow>
                              <TableCell>{member.name}</TableCell>
                              <TableCell>{member.email}</TableCell>
                              <TableCell>
                                {member.aliases?.length > 0 && member.aliases.join(', ')}
                              </TableCell>
                              <TableCell>
                                {member.isPrimary &&
                                 <CheckCircleOutlineIcon style={{color: 'green'}} />
                                }
                              </TableCell>
                              <TableCell>
                                {isPrimaryContact &&
                                  <>
                                    <Tooltip title="Make Primary Contact">
                                      <Button
                                        onClick={() => handleChangePrimary(index)}
                                      >
                                        <LabelImportantIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Remove Team Member">
                                      <Button
                                        onClick={() => handleRemoveMember(index)}
                                      >
                                        <ClearIcon style={{color: 'red'}}/>
                                      </Button>
                                    </Tooltip>
                                  </>
                                }
                              </TableCell>
                            </TableRow>
                          )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
        <Grid item>
            <h2>Add Team Member</h2>
        </Grid>
        <Grid item>
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="member-email"
                      label="Email"
                      onChange={handleAddMemberTextFieldChange}
                      value={memberEmail}
                      variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                      disabled={memberEmail.length === 0}
                      onClick={handleAddMemberClick}
                      color="primary"
                      variant="outlined"
                      className={classes.addMember}
                    >
                      Add Member
                    </Button>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    );
}
