import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {
    Grid,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    makeStyles
} from '@material-ui/core';
import axios from 'axios';

const REGISTER_INITIAL_TEAM_PRIMARY = "/addNewTeamAndPrimary";
const GET_COMPANY_INFO_FROM_COMPANY_ID = "/getCompanyInfoFromArkeoCompanyId";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: "80%",
      marginLeft: "10%",
      minHeight: "100vh"
    },
}));

export default function AddTeamAndPrimary(){

    const classes = useStyles();

    const [companyName, setCompanyName] = useState("");
    const [teamName, setTeamName] = useState("");
    const [primaryEmail, setPrimaryEmail] = useState("");
    const [primaryName, setPrimaryName] = useState("");
    const [primaryPass, setPrimaryPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [arkeoCompanyId, setArkeoCompanyId] = useState();
    const [showFields, setShowFields] = useState();
    const [isTeamAdmin, setIsTeamAdmin] = useState(false);


    const handleSubmit = () => {
        if(primaryPass !== confirmPass){
            alert("passwords don't match!");
            return;
        }
        axios.post("/api" + REGISTER_INITIAL_TEAM_PRIMARY, {
            companyName: companyName,
            teamName: teamName,
            isTeamAdmin: isTeamAdmin,
            primaryEmail: primaryEmail,
            primaryName: primaryName,
            primaryPass: primaryPass,
            arkeoCompanyId: arkeoCompanyId
        })
        .then(res => {
            alert(res.data);
            setCompanyName("");
            setTeamName("");
            setPrimaryEmail("");
            setPrimaryName("");
            setPrimaryPass("");
            setConfirmPass("");
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    const handleCompanySearch = () => {
        var companyId = arkeoCompanyId;
        if(companyId === ""){
            alert("Company Id cannot be empty");
            return;
        }
        axios.get('/api' + GET_COMPANY_INFO_FROM_COMPANY_ID + `/${arkeoCompanyId}`)
        .then(res => {
            if(res.data === null){
                alert("There was no company found with the given company id");
                return;
            }
            setCompanyName(res.data);
            setShowFields(true);
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    return(
        <Grid container direction='column' className={classes.root} spacing={3}>
            <Grid item xs={9}>
                <h2>Add new team and primary contact</h2>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={9}>
                    <TextField fullWidth={true} variant="outlined" id="arkeoCompanyId" label="Arkeo Company Id" onChange={(e) => setArkeoCompanyId(e.target.value)} value={arkeoCompanyId}/>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" color='secondary' onClick={handleCompanySearch}>Search</Button>
                </Grid>
            </Grid>
            { showFields &&
            <>
            <Grid item xs={9}>
                <TextField fullWidth={true} variant="outlined" id="companyName" disabled label="Company name" onChange={(e) => setTeamName(e.target.value)} value={companyName}/>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField fullWidth={true} variant="outlined" id="teamName" label="Team name" onChange={(e) => setTeamName(e.target.value)} value={teamName}/>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox fullWidth={true} variant="outlined" id="teamAdmin" label="Team Admin" onChange={(e) => setIsTeamAdmin(e.target.checked)} value={isTeamAdmin}/>
                      }
                      label="Make Team Admin"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
                <TextField fullWidth={true} variant="outlined" id="primaryEmail" label="Primary Contact Email" onChange={(e) => setPrimaryEmail(e.target.value)} value={primaryEmail}/>
            </Grid>
            <Grid item xs={9}>
                <TextField fullWidth={true} variant="outlined" id="primaryName" label="Primary Contact Name" onChange={(e) => setPrimaryName(e.target.value)} value={primaryName}/>
            </Grid>
            <Grid item xs={9}>
                <TextField fullWidth={true} variant="outlined" id="primaryPass" label="Primary Contact Password" onChange={(e) => setPrimaryPass(e.target.value)} value={primaryPass}/>
            </Grid>
            <Grid item xs={9}>
                <TextField fullWidth={true} variant="outlined" id="confirmPass" label="Confirm Contact Password" onChange={(e) => setConfirmPass(e.target.value)} value={confirmPass}/>
            </Grid>
            <Grid item xs={9}>
                <Button variant="contained" color='primary' onClick={handleSubmit}>Submit</Button>
            </Grid>
            </>
            }
        </Grid>
    );

}
