/* DashboardView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React, {useEffect, useState} from "react";
import {Redirect} from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import {
  Grid,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import SideBar from "./static/SideBar";
import Account from "./static/Account";
import QuoteView from "./components/QuoteView/QuoteView";
import ContactDialog from "./common/ContactDialog";
import Home from "./static/Home";
import Settings from "./static/Settings";
import ErrorPage from "./static/ErrorPage";
// import TicketCard from "./common/TicketCard";

// import components
import OrderStatusSearch from "./components/OrderStatus/OrderStatusSearch";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: "100vw",
    minHeight: "100vh",
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 50,
  },
  dialog: {
    minWidth: 800,
    margin: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1),
  },
  flows: {
    margin: 30,
  },
  italics: {
    fontStyle: "italic",
  },
  metadata: {
    backgroundColor: "#F4F4F4",
    paddingBottom: 50,
  },

}));

function DashboardView(props) {

  const isMobile = !useMediaQuery('(min-width:600px)');

  const classes = useStyles();
  const [contactRCS, setContactRCS] = useState(false);
  const [pageState, setPageState] = useState(0); // 0 - blank page, 1 - dashboard, 2 - redirect
  // const [zendeskTickets, setZendeskTickets] = useState([]);
  const [activeComponent, setActiveComponent] = useState("home");

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(currUser) {
      if (currUser) {
        setPageState(1);
      }
      else{
        setPageState(2);
      }
    });
    setActiveComponent(props.activeComponent);
  }, [props]);

  return (
    <>
    {pageState === 2 && <Redirect to="/login" />}
    {pageState === 1 &&
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item xs={1}>
        <SideBar
          clickHome={() => {
            props.handleActiveComponentChange("home");
          }}

          clickQuote={() => {
            props.handleActiveComponentChange("quote");
          }}
          clickEmail={() => setContactRCS(true)}
          clickSettings={() => {
            props.handleActiveComponentChange("settings");
          }}
        />
      </Grid>
      </Hidden>
      <Grid item xs>
        <Grid container direction="column">
          <Grid item xs={12}>
            {/*This should change to Order Status page*/}
            {activeComponent === "orderStatus" &&
              <div style={{marginTop: 8, marginLeft: "5vw"}}>
                {/* <Typography style={{fontSize: 35, fontWeight: "bold"}}>
                  Order Status
                </Typography> */}
                <OrderStatusSearch />
              </div>
            }
            {activeComponent === "quote" &&
              (isMobile ?
                <ErrorPage
                  errorCode={`301`}
                  message={`At this time our online quoting & order placement
                    system is only available on desktop. We apologize for any
                    inconvenience.`}
                />
                :
                <QuoteView />
              )
            }
            {activeComponent === "home" &&
              <Home />
            }
            {/*
            {zendeskTickets.length > 0 &&
              zendeskTickets.map((ticketObj) => {
                let ticket = ticketObj.ticket;
                let comments = ticketObj.comments;
                return (
                  <TicketCard
                    description={ticket.description}
                    createdAt={ticket.created_at}
                    subject={ticket.subject}
                    type={ticket.type}
                    updatedAt={ticket.updated_at}
                    orderNum={ticket.fields[1].value}
                    comments = {ticketObj.comments}
                  />
                )
              })
            }*/}
            {activeComponent === "account" && <Account/>}
            {activeComponent === "settings" && 
              <Settings />
            }
            
          </Grid>

        </Grid>
      </Grid>
      <ContactDialog open={contactRCS} onClose={() => setContactRCS(false)}/>
    </Grid>
    }
    </>
  );
}

export default DashboardView;
